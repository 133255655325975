import React, { ChangeEvent } from 'react';
// @ts-ignore
import uuid from 'react-uuid';
import RefParser from 'json-schema-ref-parser';
import {
  Typography,
  TextField,
  Switch,
  FormControl,
  FormLabel,
  InputLabel,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  FormHelperText,
  Grid,
  Chip,
  Tooltip,
  FormControlLabel,
  Link,
  Button,
  ButtonGroup,
} from '@mui/material';
import { WithStyles } from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import { Add, Close, InfoOutlined } from '@mui/icons-material';
import Autocomplete, { AutocompleteInputChangeReason } from '@mui/material/Autocomplete';
import i18next from 'i18next';
import clsx from 'clsx';
import moment from 'moment';
import Ajv from 'ajv';
import { JsonInputNative } from './';
import { getTheme } from '../common/theme';

/** SchemaFormRead **/
const SchemaFormReadStyles = () => {
  const theme = getTheme();
  return {
    json: {
      fontSize: '0.9rem',
    },
    container: {
      minHeight: 64,
      paddingLeft: 14,
      position: 'relative' as const,
    },
    borderLeft: {
      borderLeft: `2px solid ${theme.palette.primary.main}`,
      position: 'absolute' as const,
      left: 0,
      top: 6,
      bottom: 6,
    },
    borderLeftReadOnly: {
      borderLeftColor: theme.palette.action.disabled,
    },
    value: {
      marginTop: 6,
      fontSize: 16,
      'word-break': 'break-all',
      maxWidth: '100%',
    },
    listContainer: {
      marginTop: 4,
    },
    description: {
      fontSize: 12,
      position: 'relative' as const,
      marginTop: -8,
      color: theme.palette.text.disabled,
    },
    chip: {
      marginRight: 2,
      marginTop: 3,
      marginBottom: 4,
      maxWidth: '100%',
    },
    unit: {
      fontSize: 12,
    },
  };
};

type Unique = {
  unique: string;
  dataValue: any;
};

type ValueObject = Unique | any;

export type SchemaFormReadProps = WithStyles<any> & {
  page: string;
  message: string;
  standByText: string;
  type: 'string' | 'number' | 'boolean' | 'integer' | 'object' | 'array' | 'json' | 'textarea' | 'switch';
  value?: string | number | boolean | { [key: string]: any } | any[] | Unique;
  label: string;
  description?: string;
  unit?: string;
  isEditable: boolean;
  navigate?: (id: string) => string;
  default?: Record<string, any>;
};

const dateRegex = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{3}Z$/;
class _SchemaFormRead extends React.Component<SchemaFormReadProps> {
  string = (): React.ReactNode => {
    console.log(this.props)
    if (!this.props.value) return this.renderContent();
    const value =
      !!this.props.value && dateRegex.test(this.props.value as string) ? (
        <Tooltip title={this.props.value} placement="top-start">
          <Typography variant="subtitle1" className={this.props.classes.value}>
            {`${moment(this.props.value as string).format('YYYY-MM-DD HH:mm:ss')} (${moment(
              this.props.value as string,
            ).fromNow()})`}
          </Typography>
        </Tooltip>
      ) : this.props.navigate && this.props.value && this.props.value !== 'NONE' ? (
        <Typography variant="subtitle1" className={this.props.classes.value}>
          <Link underline="none" href={this.props.navigate(this.props.value as string)}>
            {this.props.value}{' '}
            {this.props.unit ? <span className={this.props.classes.unit}>{this.props.unit}</span> : null}
          </Link>
        </Typography>
      ) : (
        <Typography variant="subtitle1" className={this.props.classes.value}>
          {((this.props.page && this.props.page === "deviceContract") && this.props.label === "Contract Status" && this.props.standByText) ? this.props.standByText : (this.props.value || this.props.value === '' ? this.props.value : 'N/A')}{' '}
          {this.props.unit ? <span className={this.props.classes.unit}>{this.props.unit}</span> : null}
          {((this.props.page && this.props.page === "deviceContract") && this.props.label === "Contract Status") ? <div>{this.props.message}</div> : ""}
        </Typography>
      );
    return this.renderContent(value);
  };

  number = (): React.ReactNode => {
    const value = (
      <Typography variant="subtitle1" className={this.props.classes.value}>
        {['', undefined, null].includes(this.props.value as any) ? 'N/A' : this.props.value}{' '}
        {this.props.unit ? <span className={this.props.classes.unit}>{this.props.unit}</span> : null}
      </Typography>
    );
    return this.renderContent(value);
  };

  boolean = (): React.ReactNode => {
    const value = (
      <Typography variant="subtitle1" className={this.props.classes.value}>
        {`${this.props.value !== undefined ? this.props.value : 'N/A'}`}{' '}
        {this.props.unit ? <span className={this.props.classes.unit}>{this.props.unit}</span> : null}
      </Typography>
    );
    return this.renderContent(value);
  };

  switch = (): React.ReactNode => {
    const value = (
      <Typography variant="subtitle1" className={this.props.classes.value}>
        {`${this.props.value !== undefined ? this.props.value : 'N/A'}`}{' '}
        {this.props.unit ? <span className={this.props.classes.unit}>{this.props.unit}</span> : null}
      </Typography>
    );
    return this.renderContent(value);
  };

  integer = (): React.ReactNode => {
    const value = (
      <Typography variant="subtitle1" className={this.props.classes.value}>
        {['', undefined, null].includes(this.props.value as any) ? 'N/A' : this.props.value}{' '}
        {this.props.unit ? <span className={this.props.classes.unit}>{this.props.unit}</span> : null}
      </Typography>
    );
    return this.renderContent(value);
  };

  array = (): React.ReactNode => {
    const value = (this.props.value as string[]).length ? (
      <div className={this.props.classes.listContainer}>
        {(this.props.value as string[]).map((item) => (
          <Chip size="small" key={item} label={item} className={this.props.classes.chip} />
        ))}
      </div>
    ) : (
      <Typography variant="subtitle1" className={this.props.classes.value}>
        N/A
      </Typography>
    );
    return this.renderContent(value);
  };

  getObjectValue = (value: any, key: string) => {
    const extra =
      this.props.default &&
      this.props.default[key] &&
      this.props.default[key].toString() ===
        (value?.dataValue ? value.dataValue : value?.dataValue === null ? 'null' : value)
        ? ' (default)'
        : '';
    return `${
      value?.dataValue
        ? value.dataValue
        : value?.dataValue === null || value?.dataValue === false
        ? value?.dataValue === false
          ? false
          : 'null'
        : value
    }${extra}`;
  };

  object = (): React.ReactNode => {
    const value = Object.entries(this.props.value as any).length ? (
      <div className={this.props.classes.listContainer}>
        {Object.entries(this.props.value as ValueObject).map(([key, value]: [string, any]) => (
          <Chip
            size="small"
            key={key}
            label={`${key}=${this.getObjectValue(value, key)}`}
            className={this.props.classes.chip}
          />
        ))}
      </div>
    ) : (
      <Typography variant="subtitle1" className={this.props.classes.value}>
        N/A
      </Typography>
    );
    return this.renderContent(value);
  };

  json = (): React.ReactNode => {
    const value = ![undefined, null, '', {}, []].includes(this.props.value as any) ? (
      <div className={this.props.classes.json}>
        <pre id="jsonReadHeight">{JSON.stringify(this.props.value as any, null, 2)}</pre>
      </div>
    ) : (
      <Typography variant="subtitle1" className={this.props.classes.value}>
        N/A
      </Typography>
    );
    return this.renderContent(value);
  };

  textarea = (): React.ReactNode => {
    const value = (
      <Typography variant="subtitle2" component="pre" className={this.props.classes.value}>
        <code>{this.props.value ? `${this.props.value}` : 'N/A'}</code>
      </Typography>
    );
    return this.renderContent(value);
  };

  renderContent = (value?: React.ReactNode): React.ReactNode => {
    return (
      <div className={this.props.classes.container}>
        {/* {(this.props.page && this.props.page === "deviceContract") ? (
          <div
            className={this.props.classes.borderLeftReadOnly}
          />
        ) : (
          <div
            className={clsx(this.props.classes.borderLeft, {
              [this.props.classes.borderLeftReadOnly]: !this.props.isEditable,
            })}
          />
        )} */}
        <div
          className={clsx(this.props.classes.borderLeft, {
            [this.props.classes.borderLeftReadOnly]: !this.props.isEditable,
          })}
        />
        <Typography variant="body2" className={this.props.classes.description}>
          {this.props.label}
          {this.props.description ? (
            <Tooltip title={this.props.description} placement="left">
              <InfoOutlined
                fontSize="small"
                style={{
                  position: 'absolute' as const,
                  color: getTheme().palette.text.disabled,
                  cursor: 'pointer' as const,
                  top: 0,
                  right: 6,
                  backgroundColor: 'white',
                  display: 'block',
                  zIndex: 1,
                  borderRadius: '200%',
                }}
              />
            </Tooltip>
          ) : null}
        </Typography>
        {value}
      </div>
    );
  };

  render = (): React.ReactNode => this[this.props.type]();
}

export const SchemaFormRead = withStyles(SchemaFormReadStyles)(_SchemaFormRead);

/** SchemaFormInput **/
const SchemaFormInputStyles = () => {
  const theme = getTheme();
  return {
    settingsIconGrid: {
      display: 'flex',
      alignItems: 'center',
    },
    settingsIconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 32,
      height: 32,
      color: theme.palette.secondary.main,
      '& svg': {
        fontSize: 32,
        width: '16px',
        height: '16px',
      },
    },
    expand: {
      position: 'absolute' as const,
      top: -6,
      right: 0,
      height: 12,
      width: 12,
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
      '& svg': {
        marginTop: -6,
        fontSize: 12,
        width: '16px',
        height: '16px',
      },
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    ellipsis: {
      position: 'absolute' as const,
      fontSize: 12,
      paddingLeft: 1,
      color: theme.palette.text.disabled,
      right: theme.spacing(3),
      backgroundColor: '#fff',
    },
    labelBackground: {
      backgroundColor: '#fff',
      padding: '0 4px',
      left: -4,
    },
    labelIcon: {
      color: theme.palette.text.disabled,
      fontSize: 16,
      margin: '-1px 0 -2px 4px',
    },
    labelIconActive: {
      color: theme.palette.primary.main,
    },
    inputBase: {
      display: 'flex',
      flexWrap: 'wrap' as const,
      padding: 9,
      paddingRight: 32,
      '& > *:not(:last-child):not(input)': {
        flex: 0,
        margin: '3px 3px',
      },
      '& > input': {
        flex: 1,
        minWidth: '20%',
        padding: '9.5px 4px',
        '&.Mui-disabled': {
          minWidth: 0,
          width: 0,
        },
      },
      '& > .MuiInputAdornment-root.MuiInputAdornment-positionEnd': {
        position: 'absolute' as const,
        right: 0,
        marginTop: 0,
      },
    },
    autocompleteMultiInput: {
      '&:not(:first-child)': {
        paddingLeft: '6px !important',
      },
    },
    infoIconFocus: {
      color: theme.palette.primary.main,
    },
    infoIconError: {
      color: theme.palette.error.main,
    },
    booleanFormControl: {
      width: '100%',
      marginLeft: 0,
      height: 56,
      padding: '8px 14px',
      border: '1px solid rgba(0, 0, 0, .23)',
      borderRadius: 4,
      '&:hover:not(.Mui-disabled)': {
        borderColor: 'rgba(0, 0, 0, .97)',
      },
      '& > span.MuiFormControlLabel-label': {
        position: 'absolute' as const,
        left: 14,
      },
    },
    booleanFormControlFocus: {
      borderColor: `${theme.palette.primary.main} !important`,
      borderWidth: 2,
      padding: '7px 13px',
    },
    booleanFormControlError: {
      borderColor: `${theme.palette.error.main} !important`,
    },
    jsonFormControl: {
      width: '100%',
      marginLeft: 0,
      height: 'auto',
      padding: '8px 9px',
      borderWidth: 1,
      borderStyle: 'solid' as const,
      borderColor: 'rgba(0, 0, 0, .23)',
      borderRadius: 4,
      '&:hover:not(.Mui-disabled)&:not(:focus-within)': {
        borderColor: 'rgba(0, 0, 0, .97)',
      },
      '& > label': {
        position: 'absolute' as const,
        left: -4,
        zIndex: 1,
        transform: 'translate(14px, -20px) scale(0.75)',
      },
      '&:focus-within': {
        borderColor: `${theme.palette.primary.main}`,
        borderWidth: 2,
        padding: '7px 8px',
        '& > label': {
          color: `${theme.palette.primary.main}`,
          marginTop: -1,
          marginLeft: -1,
        },
      },
    },
    jsonFormControlError: {
      borderColor: `${theme.palette.error.main} !important`,
      '& > label': {
        color: `${theme.palette.error.main} !important`,
      },
    },
    objectFormControl: {
      minHeight: 56,
      padding: '8px 32px 8px 14px',
      border: '1px solid rgba(0, 0, 0, .23)',
      borderRadius: 4,
      '&:hover:not(.Mui-disabled)': {
        borderColor: 'rgba(0, 0, 0, .97)',
      },
    },
    objectFormControlFocus: {
      borderColor: `${theme.palette.primary.main} !important`,
      borderWidth: 2,
      padding: '7px 31px 7px 13px',
    },
    objectFormControlError: {
      borderColor: `${theme.palette.error.main} !important`,
    },
    objectFormControlDisabled: {
      borderColor: `${theme.palette.error.main} !important`,
    },
    editDisabled: {
      '& .MuiFormControl-root, & fieldset, & .MuiFormControlLabel-root': {
        borderStyle: 'dashed !important' as 'dashed',
      },
    },
    selectMappingValues: {
      '&& .MuiSelect-select': {
        padding: '10px',
      },
    },
  };
};

export type SchemaFormInputProps = WithStyles<typeof SchemaFormInputStyles> & {
  state: 'CREATE' | 'UPDATE';
  depth?: number;
  inputGrid?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  data: Omit<RefParser.JSONSchema, 'type'> & {
    key: string;
    page: string;
    message: string;
    standByText: string;
    type: 'string' | 'number' | 'boolean' | 'integer' | 'object' | 'array';
    displayName?: string;
    readOnly?: boolean;
    mandatoryKeys?: string[];
    mappingValues?: string[];
    variant?: 'select' | 'json' | 'textarea' | 'mapping' | 'switch';
    options?: any[];
    hiddenIn?: ('CREATE' | 'UPDATE')[];
    editableIn?: ('CREATE' | 'UPDATE')[];
    hiddenIf?: [string, 'EQUAL' | 'NOT_EQUAL', string][];
    info?: string;
    focus?: boolean;
    disabled?: boolean;
  };
  disabled?: boolean;
  required?: boolean;
  hideUnset?: boolean;
  json?: {
    objectPath?: string;
    schema: { [key: string]: any };
  };
  objectKey: string;
  depthToShowObjectTitleInLabel?: number;
  defaultValue?: { [key: string]: any };
  options?: string[];
  default?: Record<string, any>;
  onValueChange?(value: string): Promise<string[]>;
  navigate?: (id: string) => string;
  onChange?: (path: string, value: any, isValid: boolean, callback?: (error: any) => void) => void;
  hideLabel?: boolean;
  jsonInputHeight?: string;
};

type SchemaFormInputState = {
  tmpValue: string;
  tmpOptions: string[];
  value?: string | number | boolean | { [key: string]: any } | any[];
  defaultValue?: string | number | boolean | { [key: string]: any } | any[];
  error: boolean;
  tmpError: boolean;
  helperText?: string;
  showExpand: boolean;
  hasFocus: boolean;
  showErrorCreate?: boolean;
};
class _SchemaFormInput extends React.Component<SchemaFormInputProps, SchemaFormInputState> {
  state: SchemaFormInputState;
  arrayDebouncer: any;
  ajv: any;

  constructor(props: SchemaFormInputProps) {
    super(props);
    const defaultValue = this.getDefaultValue();
    this.state = {
      tmpValue: '',
      tmpOptions: [],
      value: defaultValue !== undefined ? JSON.parse(JSON.stringify(defaultValue)) : undefined,
      defaultValue,
      error: false,
      tmpError: false,
      helperText: undefined,
      showExpand: false,
      hasFocus: false,
      showErrorCreate: false,
    };
    if (props.json) {
      this.ajv = new Ajv({ allErrors: true, useDefaults: true, nullable: true });
      this.ajv.addSchema(props.json.schema, 'schema');
    }
  }

  componentDidMount = (): void => {
    const { defaultValue, value } = this.state;
    const isEmptyArray = Array.isArray(defaultValue) && defaultValue.length === 0;
    if (this.props.state === 'CREATE' && defaultValue !== undefined && !isEmptyArray && defaultValue !== '') {
      this.onChange(defaultValue);
    }
    // Adds unique id to objects
    if (value && typeof value === 'object' && !Array.isArray(value) && this.props.data?.variant === 'mapping') {
      this.setState({ value: this.addIdToObject(value) });
    }
  };

  componentDidUpdate = () => {
    const { defaultValue, objectKey } = this.props;
    if (objectKey === 'shadowTemplateAction.parameterSubstitutionMapping' && defaultValue) {
      const valueKeys = Object.keys(this.state.value as Record<string, string>);
      const defaultKeys = Object.keys(defaultValue.shadowTemplateAction!.parameterSubstitutionMapping);
      if (valueKeys.length !== defaultKeys.length || !valueKeys.every((val, i) => val === defaultKeys[i])) {
        this.setState({ value: this.addIdToObject(defaultValue.shadowTemplateAction!.parameterSubstitutionMapping) });
      }
    } else if (objectKey.startsWith('shadow') && defaultValue && this.state.value !== undefined) {
      let setState = true;
      const ray = objectKey.split('.');
      let newData = defaultValue;
      ray.forEach((item) => {
        if (setState && {}.hasOwnProperty.call(newData, item)) {
          newData = newData[item];
        } else {
          setState = false;
          return;
        }
      });

      if (newData !== undefined) {
        if (typeof newData !== 'object' || Object.keys(newData).length > 0) {
          if (
            typeof newData === 'object'
              ? JSON.stringify(newData) !== JSON.stringify(this.state.value)
              : newData !== this.state.value
          ) {
            if (setState) {
              this.setState({ value: newData });
            }
          }
        }
      }
    }
  };

  addIdToObject = (obj: { [key: string]: ValueObject }): Record<string, any> => {
    return Object.entries(obj as { [key: string]: ValueObject }).reduce((acc: any, [k, v]) => {
      if (!v?.unique) {
        acc[k] = { dataValue: v, unique: uuid() };
      } else {
        acc[k] = v;
      }
      return acc;
    }, {});
  };

  get depth(): number {
    if (typeof this.props.depth === 'number') {
      if (this.props.depth < 3) return this.props.depth;
      return 3;
    }
    return 1;
  }

  get label(): string {
    if (this.props.hideLabel) return '';
    else if (this.props.data.displayName) return this.props.data.displayName;
    return `${
      this.depth >= (this.props.depthToShowObjectTitleInLabel || 3)
        ? this.props.objectKey
            .split('.')
            .slice(this.props.depthToShowObjectTitleInLabel || 3, -1)
            .join(' ')
        : ''
    } ${this.props.data.key.replace('(', ' (')}`;
  }

  get disabled(): boolean {
    if ('disabled' in this.props.data) {
      return this.props.data?.disabled ? true : false;
    } else {
      return !!(
        (!!this.props.data.editableIn && !this.props.data.editableIn.includes(this.props.state)) ||
        this.props.disabled ||
        this.props.data.readOnly
      );
    }
  }

  get isEditable(): boolean {
    return !!(
      !this.props.data.readOnly &&
      (!this.props.data.editableIn ||
        (!!this.props.data.editableIn && this.props.data.editableIn.includes(this.props.state)))
    );
  }

  get inputProps(): any {
    if (!(this.props.data as any).unit) return undefined;
    return {
      endAdornment: (
        <InputAdornment position="end">
          <Typography variant="caption">{(this.props.data as any).unit}</Typography>
        </InputAdornment>
      ),
    };
  }

  getReadValue = () => {
    return !!this.props.data.options &&
      !!this.props.data.options[0] &&
      typeof this.props.data.options[0] === 'object' &&
      this.props.data.variant !== 'json'
      ? this.props.data.options.find((o) => o.value === this.state.value)?.label || this.state.value
      : this.state.value;
  };

  onFocus = (): void => this.setState({ hasFocus: true });

  onBlur = (): void => this.setState({ hasFocus: false });

  isUnset = (
    keys: string[] = this.props.objectKey.split('.'),
    nestedValue: { [key: string]: any } | any = JSON.parse(JSON.stringify(this.props.defaultValue)),
  ): boolean => {
    if ({}.hasOwnProperty.call(nestedValue, keys[0])) {
      if (keys.length === 1) return false;
      return this.isUnset(keys.slice(1), nestedValue[keys[0]]);
    }
    return true;
  };

  getDefaultValue = (
    keys: string[] = this.props.objectKey.split('.'),
    nestedValue: { [key: string]: any } | any = (!!this.props.defaultValue &&
      JSON.parse(JSON.stringify(this.props.defaultValue))) ||
      undefined,
  ): string | number | boolean | { [key: string]: any } | any[] => {
    if (
      !!nestedValue &&
      {}.hasOwnProperty.call(nestedValue, keys[0]) &&
      (keys.length > 1 || (keys.length === 1 && nestedValue[keys[0]] !== null))
    ) {
      if (keys.length === 1) return nestedValue[keys[0]] as string | number | boolean;
      return this.getDefaultValue(keys.slice(1), nestedValue[keys[0]]);
    }
    if ({}.hasOwnProperty.call(this.props.data, 'default')) return this.props.data.default as any;
    switch (this.props.data.type) {
      case 'string':
        return '';
      case 'number':
        return '';
      case 'boolean':
        return false;
      case 'integer':
        return '';
      case 'array':
        return [];
      case 'object':
        return {};
    }
  };

  onChange = (
    value: any,
    isTmpValue?: boolean,
    error?: boolean,
    message?: string,
    disableSave?: boolean,
    callback?: (error: any) => void,
  ): void => {
    if (isTmpValue) {
      this.setState({ tmpValue: value });
    } else if (!error) {
      this.setState({ value });
    }
    this.validate(value, isTmpValue, error, message, disableSave, callback);
  };

  runValueChange = async (value: string): Promise<void> => {
    this.setState({ tmpOptions: await this.props.onValueChange!(value) });
  };

  onValueChange = (_: React.ChangeEvent<unknown>, value: any, reason: AutocompleteInputChangeReason): void => {
    if (this.arrayDebouncer) clearTimeout(this.arrayDebouncer);
    if (reason === 'input') {
      this.arrayDebouncer = setTimeout(() => this.runValueChange(value), 1000);
    }
  };

  validate = (
    value: any,
    isTmpValue?: boolean,
    error?: boolean,
    message?: string,
    disableSave?: boolean,
    callback?: (error: any) => void,
  ): void => {
    if (this.props.data.type === 'object' && this.props.data.variant === 'json') {
      return this.jsonValidator(value, error, message, disableSave, callback);
    }
    switch (
      !!isTmpValue && !!this.props.data.items
        ? (this.props.data.items as RefParser.JSONSchema).type
        : this.props.data.type
    ) {
      case 'string':
        return this.stringValidator(value, isTmpValue);
      case 'number':
        return this.numberValidator(value, isTmpValue);
      case 'boolean':
        return this.booleanValidator(value, isTmpValue);
      case 'integer':
        return this.integerValidator(value, isTmpValue);
      case 'array':
        return this.arrayValidator(value);
      case 'object':
        return this.objectValidator(value);
      default:
        return this.stringValidator(value, isTmpValue);
    }
  };

  handleValidation = (
    value: any,
    error?: boolean,
    helperText?: string,
    isTmpValue?: boolean,
    disableSave?: boolean,
    callback?: (error: any) => void,
  ) => {
    if (isTmpValue) {
      this.setState({ tmpError: !!error, helperText });
    } else {
      const { defaultValue } = this.state;
      const isEmptyArray = Array.isArray(defaultValue) && defaultValue.length === 0;
      if (
        this.props.state === 'CREATE' &&
        defaultValue !== undefined &&
        !isEmptyArray &&
        defaultValue !== '' &&
        !this.state.showErrorCreate
      ) {
        this.setState({ showErrorCreate: true });
      } else {
        this.setState({ error: !!error, helperText });
      }
    }
    if (!isTmpValue && !!this.props.onChange) {
      // Removed key on objects when saving data and sends to parent component
      let valueNoKey = value;
      if (value && typeof value === 'object' && !Array.isArray(value)) {
        valueNoKey = Object.entries(value as { [key: string]: ValueObject }).reduce((acc: any, [k, v]) => {
          if (v && typeof v === 'object' && 'unique' in v) {
            acc[k] = v.dataValue;
          } else {
            acc[k] = v;
          }
          return acc;
        }, {});
      }
      this.props.onChange(this.props.objectKey, valueNoKey, disableSave ? !disableSave : !error, callback);
    }
  };

  createJsonObjectForValidation = (valueOfLastObject: { [key: string]: any }): { [key: string]: any } => {
    if (!this.props.json!.objectPath) return valueOfLastObject;
    return this.props
      .json!.objectPath.split('.')!
      .reduceRight((obj, next) => ({ [next]: obj }), JSON.parse(JSON.stringify(valueOfLastObject)));
  };

  toolTipCreate = (value: string): string => {
    const { info } = this.props.data;
    if (!info || !value) return '';
    if (value.length && (value.startsWith('*') || value.startsWith('$*'))) {
      return info.split('starting')[0];
    }
    return `${info} ${value}`;
  };

  jsonValidator = (
    value: any,
    error?: boolean,
    message?: string,
    disableSave?: boolean,
    callback?: (error: any) => void,
  ): void => {
    // custom error
    if (message) return this.handleValidation(this.state.value, true, message, false, true, callback);

    // invalid json
    if (error) return this.handleValidation(this.state.value, true, i18next.t('error.validator.invalidJson'));

    // required
    if (this.props.required && [undefined, null, '', {}].includes(value as any)) {
      return this.handleValidation(value, true, i18next.t('error.validator.required'));
    }
    // validate json against json schema
    if (this.props.json && !this.ajv.validate('schema', this.createJsonObjectForValidation(value as any))) {
      let errorMessage = i18next.t('error.validator.doesNotMatchJsonSchema');
      if (this.ajv.errors && this.ajv.errors.length) {
        this.ajv.errors.forEach((error: any) => {
          switch (error.keyword) {
            case 'additionalProperties':
            case 'type':
            case 'maximum':
            case 'minimum':
            case 'required':
              errorMessage += ` *${error.dataPath.split('.').slice(-1)[0]} ${error.message}`;
              break;
            default:
              errorMessage += ` *${error.message}`;
              break;
          }
        });
      }
      const isError =
        this.ajv.errors.length === 1 && this.ajv.errors[0].keyword === 'additionalProperties' ? false : true;
      return this.handleValidation(this.state.value, isError, errorMessage);
    }
    this.handleValidation(value, false, undefined, false, disableSave, callback);
  };

  stringValidator = (value: any, isTmpValue?: boolean): void => {
    const data = isTmpValue ? ((this.props.data.items || {}) as RefParser.JSONSchema) : this.props.data;
    // required
    if (!isTmpValue && this.props.required && [undefined, ''].includes(value as any)) {
      return this.handleValidation(value, true, i18next.t('error.validator.required'), isTmpValue);
    }

    // minLength
    if (
      typeof data.minLength !== 'undefined' &&
      (typeof value === 'undefined' || (value as string)!.length < data.minLength)
    ) {
      return this.handleValidation(
        value,
        true,
        i18next.t('error.validator.minLength').replace('%minLength%', data.minLength!.toString()),
        isTmpValue,
      );
    }

    // maxLength
    if (
      typeof data.maxLength !== 'undefined' &&
      typeof value !== 'undefined' &&
      data.maxLength < (value as string)!.length
    ) {
      return this.handleValidation(
        value,
        true,
        i18next.t('error.validator.maxLength').replace('%maxLength%', data.maxLength!.toString()),
        isTmpValue,
      );
    }

    // pattern
    if (typeof data.pattern !== 'undefined' && !!value && !new RegExp(data.pattern).test(value as string)) {
      const regex = new RegExp(data.pattern);
      const chars: string[] = [];
      (value as string).split('').forEach((char) => {
        if (!regex.test(char)) chars.push(char);
      });
      return this.handleValidation(
        value,
        true,
        i18next.t('error.validator.pattern').replace('%chars%', chars.join('')).replace('%pattern%', data.pattern!),
        isTmpValue,
      );
    }

    this.handleValidation(value, false, undefined, isTmpValue);
    // Validate array or object if tmpValue is true
    if (isTmpValue) this.validate(this.state.value);
  };

  numberValidator = (value: any, isTmpValue?: boolean): void => {
    const data = isTmpValue ? ((this.props.data.items || {}) as RefParser.JSONSchema) : this.props.data;
    // required NOT tmpValue
    if (!isTmpValue && this.props.required && [undefined, ''].includes(value as any))
      return this.handleValidation(value, true, i18next.t('error.validator.required'), isTmpValue);

    // minimum
    if (typeof data.minimum !== 'undefined' && (typeof value === 'undefined' || value === '' || value! < data.minimum))
      return this.handleValidation(
        value,
        true,
        i18next.t('error.validator.minimum').replace('%minimum%', data.minimum!.toString()),
        isTmpValue,
      );

    // maximum
    if (typeof data.maximum !== 'undefined' && typeof value !== 'undefined' && data.maximum < value!)
      return this.handleValidation(
        value,
        true,
        i18next.t('error.validator.maximum').replace('%maximum%', data.maximum!.toString()),
        isTmpValue,
      );

    this.handleValidation(value, false, undefined, isTmpValue);
    // Validate array or object if tmpValue is true
    if (isTmpValue) this.validate(this.state.value);
  };

  booleanValidator = (value: any, isTmpValue?: boolean): void => {
    this.handleValidation(value, isTmpValue);
  };

  integerValidator = (value: any, isTmpValue?: boolean): void => {
    const data = isTmpValue ? ((this.props.data.items || {}) as RefParser.JSONSchema) : this.props.data;
    // required NOT tmpValue
    if (!isTmpValue && this.props.required && [undefined, ''].includes(value as any))
      return this.handleValidation(value, true, i18next.t('error.validator.required'), isTmpValue);

    // integer
    if (typeof value !== undefined && value !== '' && !Number.isInteger(value as number))
      return this.handleValidation(value, true, i18next.t('error.validator.onlyInteger'), isTmpValue);

    // minimum
    if (typeof data.minimum !== 'undefined' && (typeof value === 'undefined' || value === '' || value! < data.minimum))
      return this.handleValidation(
        value,
        true,
        i18next.t('error.validator.minimum').replace('%minimum%', data.minimum!.toString()),
        isTmpValue,
      );

    // maximum
    if (typeof data.maximum !== 'undefined' && typeof value !== 'undefined' && data.maximum < value!)
      return this.handleValidation(
        value,
        true,
        i18next.t('error.validator.maximum').replace('%maximum%', data.maximum!.toString()),
        isTmpValue,
      );

    this.handleValidation(value, false, undefined, isTmpValue);
    // Validate array or object if tmpValue is true
    if (isTmpValue) this.validate(this.state.value);
  };

  arrayValidator = (value: any): void => {
    // required
    if (this.props.required && (!value || !Array.isArray(value) || (Array.isArray(value) && value.length === 0))) {
      return this.handleValidation(value, true, i18next.t('error.validator.required'));
    }
    // minLength
    if (
      typeof this.props.data.minLength !== 'undefined' &&
      (typeof value === 'undefined' || (value as any[])!.length < this.props.data.minLength)
    ) {
      return this.handleValidation(
        value,
        true,
        i18next.t('error.validator.minLengthArray').replace('%minLength%', this.props.data.minLength!.toString()),
      );
    }

    // maxLength
    if (
      typeof this.props.data.maxLength !== 'undefined' &&
      typeof value !== 'undefined' &&
      this.props.data.maxLength < (value as any[])!.length
    ) {
      return this.handleValidation(
        value,
        true,
        i18next.t('error.validator.maxLengthArray').replace('%maxLength%', this.props.data.maxLength!.toString()),
      );
    }
    this.handleValidation(value);
  };

  objectValidator = (value: any): void => {
    // required
    if (this.props.required && (!value || typeof value !== 'object')) {
      return this.handleValidation(value, true, i18next.t('error.validator.required'));
    }
    this.handleValidation(value);
  };

  string = (): React.ReactNode => {
    return (
      <TextField
        id={this.props.data.key}
        key={this.props.data.key}
        fullWidth
        type="string"
        variant="outlined"
        required={this.props.required && !this.props.data.readOnly}
        label={this.label}
        error={this.state.error && !this.disabled}
        helperText={this.state.helperText}
        FormHelperTextProps={{ id: `helper${this.props.data.key}` }}
        InputLabelProps={{
          id: `label${this.props.data.key}`,
          shrink: true,
          htmlFor: this.props.data.key ? `input${this.props.data.key}` : undefined,
          classes: { root: this.props.classes.labelBackground },
        }}
        value={this.state.value as string}
        disabled={this.disabled}
        onChange={(e) => this.onChange(e.target.value)}
        InputProps={{
          ...this.inputProps,
          id: `input${this.props.data.key}`,
          'aria-describedby': `label${this.props.data.key}`,
        }}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        autoFocus={this.props.data?.focus}
      />
    );
  };

  number = (): React.ReactNode => {
    return (
      <TextField
        id={this.props.data.key}
        key={this.props.data.key}
        fullWidth
        type="number"
        variant="outlined"
        required={this.props.required && !this.props.data.readOnly}
        label={this.label}
        error={this.state.error && !this.disabled}
        helperText={this.state.helperText}
        InputLabelProps={{ shrink: true, classes: { root: this.props.classes.labelBackground } }}
        value={this.state.value as number}
        disabled={this.disabled}
        onChange={(e) => this.onChange(e.target.value === '' ? e.target.value : parseFloat(e.target.value))}
        InputProps={this.inputProps}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        autoFocus={this.props.data?.focus}
      />
    );
  };

  switch = (): React.ReactNode => {
    return (
      <ButtonGroup color="secondary" size="small" style={{ display: 'flex', justifyContent: 'center' }}>
        <Button
          onClick={() => {
            this.onChange(this.props.data.options && this.props.data.options[0]);
          }}
          variant={
            this.props.data.options && this.state.value === this.props.data.options[0] ? 'contained' : 'outlined'
          }
        >
          {i18next.t('action.json')}
        </Button>
        <Button
          onClick={() => {
            this.onChange(this.props.data.options && this.props.data.options[1]);
          }}
          variant={
            this.props.data.options && this.state.value === this.props.data.options[0] ? 'outlined' : 'contained'
          }
        >
          {i18next.t('action.file')}
        </Button>
      </ButtonGroup>
    );
  };

  boolean = (): React.ReactNode => {
    return (
      <FormControlLabel
        key={this.props.data.key}
        label={this.label + (this.props.required && !this.props.data.readOnly ? '*' : '')}
        labelPlacement="start"
        disabled={this.disabled}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        className={clsx(this.props.classes.booleanFormControl, {
          'Mui-disabled': this.disabled,
          [this.props.classes.booleanFormControlFocus]:
            this.state.hasFocus && !(this.state.error || this.state.tmpError),
          [this.props.classes.booleanFormControlError]: this.state.error || this.state.tmpError,
        })}
        control={
          <Switch color="primary" checked={this.state.value as boolean} onChange={(_, value) => this.onChange(value)} />
        }
      />
    );
  };

  integer = (): React.ReactNode => {
    return (
      <TextField
        id={this.props.data.key}
        key={this.props.data.key}
        fullWidth
        type="number"
        variant="outlined"
        required={this.props.required && !this.props.data.readOnly}
        label={this.label}
        error={this.state.error && !this.disabled}
        helperText={this.state.helperText}
        InputLabelProps={{ shrink: true, classes: { root: this.props.classes.labelBackground } }}
        value={this.state.value as number}
        disabled={this.disabled}
        onChange={(e) => this.onChange(e.target.value === '' ? e.target.value : parseFloat(e.target.value))}
        InputProps={this.inputProps}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        autoFocus={this.props.data?.focus}
      />
    );
  };

  array = (): React.ReactNode => {
    if (!!this.props.options || !!this.props.onValueChange) return this.arrayOptions();
    return (
      <TextField
        id={this.props.data.key}
        key={this.props.data.key}
        fullWidth
        required={this.props.required && !this.props.data.readOnly}
        label={this.label}
        variant="outlined"
        error={(this.state.error || this.state.tmpError) && !this.disabled}
        helperText={
          this.state.helperText || (this.state.tmpValue.length > 0 ? i18next.t('warning.form.array') : undefined)
        }
        InputLabelProps={{ shrink: true, classes: { root: this.props.classes.labelBackground } }}
        value={this.state.tmpValue}
        disabled={this.disabled}
        onChange={(e) => this.onChange(e.target.value, true)}
        onKeyDown={(e: any) => {
          if (e.key === 'Backspace' && !this.state.tmpValue) {
            const value = ((this.state.value || []) as any[]).slice();
            value.splice(value.length - 1, 1);
            this.onChange(value);
          }
          if (e.keyCode === 13 && !!this.state.tmpValue && !this.state.tmpError) {
            this.onChange(((this.state.value || []) as any[]).concat([this.state.tmpValue.trim()]));
            this.setState({ tmpValue: '' });
          }
        }}
        onFocus={this.onFocus}
        onBlur={() => {
          if (this.state.tmpValue) {
            this.onChange(((this.state.value || []) as any[]).concat([this.state.tmpValue.trim()]));
            this.setState({ tmpValue: '' });
          }
          this.onBlur();
        }}
        InputProps={{
          className: this.props.classes.inputBase,
          startAdornment: ((this.state.value || []) as any[]).map((item, i) => (
            <Tooltip key={`key${i}`} placement="top" title={this.toolTipCreate(item.toString())}>
              <Chip
                key={i}
                size="small"
                label={item}
                disabled={this.disabled}
                style={{ maxWidth: '100%' }}
                onDelete={() => {
                  const value = ((this.state.value || []) as any[]).slice();
                  value.splice(i, 1);
                  this.onChange(value);
                }}
                onClick={() => {
                  const value = ((this.state.value || []) as any[]).slice();
                  value.splice(i, 1);
                  this.onChange(value);
                  this.setState({ tmpValue: item });
                }}
              />
            </Tooltip>
          )),
          endAdornment:
            !!this.state.tmpValue && !this.disabled && !this.state.tmpError ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="add"
                  size="small"
                  onClick={() => {
                    this.onChange((this.state.value as any[0]).concat([this.state.tmpValue.trim()]));
                    this.setState({ tmpValue: '' });
                  }}
                >
                  <Add />
                </IconButton>
              </InputAdornment>
            ) : null,
        }}
        autoFocus={this.props.data?.focus}
      />
    );
  };

  arrayOptions = (): React.ReactNode => {
    return (
      <Autocomplete
        id={`autocomplete${this.props.data.key}`}
        multiple={this.props.data.variant !== 'select'}
        filterSelectedOptions
        classes={{ input: this.props.classes.autocompleteMultiInput }}
        renderTags={(value: readonly string[], getTagProps) =>
          value.map((option: string, index: number) => (
            <Tooltip key={index} placement="top" title={this.toolTipCreate(option.toString())}>
              <Chip size={'small'} label={option} {...getTagProps({ index })} />
            </Tooltip>
          ))
        }
        options={(this.props.options || this.state.tmpOptions).filter(
          (item) => !(this.state.value as string[]).includes(item),
        )}
        disabled={this.disabled}
        getOptionLabel={(option: string) => option}
        value={this.state.value as string[]}
        isOptionEqualToValue={(option: string, value: string) => option === value}
        onChange={(_: ChangeEvent<unknown>, list: string | string[] | null) => this.onChange(list)}
        onInputChange={!this.props.options ? this.onValueChange : undefined}
        onOpen={!this.props.options && !this.state.tmpOptions.length ? () => this.runValueChange('') : undefined}
        renderInput={(params: any) => (
          <TextField
            {...params}
            id={this.props.data.key}
            error={this.state.error && !this.disabled}
            helperText={this.state.helperText}
            InputLabelProps={{
              shrink: true,
              htmlFor: `autocomplete${this.props.data.key}`,
              classes: { root: this.props.classes.labelBackground },
            }}
            label={this.label}
            variant="outlined"
            required={this.props.required && !this.props.data.readOnly}
            fullWidth
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            autoFocus={this.props.data?.focus}
          />
        )}
      />
    );
  };

  object = (): React.ReactNode => {
    return (
      <FormControl
        fullWidth
        error={this.state.error && !this.disabled}
        disabled={this.disabled}
        className={clsx(this.props.classes.objectFormControl, {
          'Mui-disabled': this.disabled,
          [this.props.classes.objectFormControlFocus]:
            this.state.hasFocus && !(this.state.error || this.state.tmpError),
          [this.props.classes.objectFormControlError]: this.state.error || this.state.tmpError,
        })}
      >
        {this.label ? (
          <InputLabel
            shrink
            htmlFor={`inputObject${this.props.data.key}`}
            style={{
              paddingRight: 4,
              paddingLeft: 4,
              backgroundColor: 'white',
            }}
          >
            {this.label + (this.props.required ? ' *' : '')}
          </InputLabel>
        ) : null}
        <div style={{ position: 'absolute', display: 'flex', top: 0, right: 2, height: '100%', alignItems: 'center' }}>
          <IconButton
            aria-label="add mapping"
            size="small"
            style={{ width: 30, height: 30 }}
            disabled={this.disabled || (this.props.data.mandatoryKeys && this.props.state === 'CREATE')}
            onClick={() => {
              const value = this.state.value as { [key: string]: ValueObject };
              this.onChange({
                ...value,
                '': {
                  unique: uuid(),
                  dataValue: this.props.data.mappingValues ? this.props.data.mappingValues[0] : '',
                },
              });
            }}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
          >
            <Add />
          </IconButton>
        </div>
        {this.state.value && Object.entries(this.state.value).length > 0 ? (
          <Grid id="grid" container spacing={1} style={{ minHeight: '100%', paddingTop: 3, paddingBottom: 3 }}>
            {Object.entries(this.state.value).map(([key, val]: [string, any]) => {
              if (val?.unique) {
                return (
                  <React.Fragment key={val.unique}>
                    <Grid item xs={6}>
                      <TextField
                        id={this.props.data.key}
                        fullWidth
                        defaultValue={key}
                        variant="standard"
                        disabled={this.disabled || this.props.data.mandatoryKeys?.includes(key)}
                        onChange={(e) => {
                          this.onChange(
                            Object.entries(this.state.value as { [key: string]: ValueObject }).reduce(
                              (acc: any, [k, v]) => {
                                if (key === k) {
                                  acc[e.target.value] = v;
                                } else {
                                  acc[k] = v;
                                }
                                return acc;
                              },
                              {},
                            ),
                          );
                        }}
                        InputProps={{
                          endAdornment:
                            this.props.data.mappingValues &&
                            !this.disabled &&
                            !this.props.data.mandatoryKeys?.includes(key) ? (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  aria-label="close"
                                  onClick={() => {
                                    this.onChange(
                                      Object.fromEntries(
                                        Object.entries(this.state.value as { [key: string]: string }).filter(
                                          ([k]) => k !== key,
                                        ),
                                      ),
                                    );
                                    this.onBlur();
                                  }}
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                >
                                  <Close />
                                </IconButton>
                              </InputAdornment>
                            ) : undefined,
                          id: `inputObject1${this.props.data.key}`,
                        }}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        autoFocus={this.props.data?.focus}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      {this.props.data.mappingValues ? (
                        <Select
                          id={`selectMapping${this.props.data.key}`}
                          className={clsx({ [this.props.classes.selectMappingValues]: true })}
                          fullWidth
                          disabled={this.disabled}
                          inputProps={{
                            id: `inputselect${this.props.data.key}`,
                            'aria-describedby': this.state.helperText
                              ? `selecthelper${this.props.data.key}`
                              : undefined,
                          }}
                          labelId={`select-mapping-label${this.props.data.key}`}
                          value={val.dataValue || (this.props.data.mappingValues && this.props.data.mappingValues[0])}
                          onChange={(e) => {
                            const newValue = Object.entries(this.state.value as { [key: string]: ValueObject }).reduce(
                              (acc: any, [k, v]) => {
                                if (key === k) {
                                  if ('unique' in v) {
                                    acc[key] = { ...v, dataValue: e.target.value };
                                  } else {
                                    acc[key] = v;
                                  }
                                } else {
                                  acc[k] = v;
                                }
                                return acc;
                              },
                              {},
                            );
                            this.onChange(newValue);
                          }}
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                        >
                          {this.props.data.mappingValues &&
                            this.props.data.mappingValues.map((option: string) => (
                              <MenuItem value={option} key={option} disabled>
                                <div>
                                  <div>{option}</div>
                                </div>
                              </MenuItem>
                            ))}
                        </Select>
                      ) : !this.props.options ? (
                        <TextField
                          id={this.props.data.key}
                          fullWidth
                          defaultValue={val.unique ? (val.dataValue === null ? 'null' : val.dataValue) : val}
                          disabled={this.disabled || this.props.data.mandatoryKeys?.includes(key)}
                          variant="standard"
                          onChange={(e) => {
                            const newValue = Object.entries(this.state.value as { [key: string]: ValueObject }).reduce(
                              (acc: any, [k, v]) => {
                                if (key === k) {
                                  if ('unique' in v) {
                                    acc[key] = { ...v, dataValue: e.target.value };
                                  } else {
                                    acc[key] = v;
                                  }
                                } else {
                                  acc[k] = v;
                                }
                                return acc;
                              },
                              {},
                            );
                            this.onChange(newValue);
                          }}
                          onFocus={this.onFocus}
                          onBlur={this.onBlur}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography
                                  variant="caption"
                                  style={{
                                    height: 25,
                                    marginBottom: -6,
                                    paddingRight: 8,
                                    backgroundColor: 'white',
                                    zIndex: 1,
                                  }}
                                >
                                  :
                                </Typography>
                              </InputAdornment>
                            ),
                            endAdornment: !this.disabled ? (
                              <InputAdornment position="end">
                                <IconButton
                                  size="small"
                                  aria-label="close"
                                  onClick={() => {
                                    this.onChange(
                                      Object.fromEntries(
                                        Object.entries(this.state.value as { [key: string]: string }).filter(
                                          ([k]) => k !== key,
                                        ),
                                      ),
                                    );
                                    this.onBlur();
                                  }}
                                  onFocus={this.onFocus}
                                  onBlur={this.onBlur}
                                >
                                  <Close />
                                </IconButton>
                              </InputAdornment>
                            ) : undefined,
                            id: `inputObject${this.props.data.key}`,
                          }}
                          autoFocus={this.props.data?.focus}
                        />
                      ) : (
                        <Autocomplete
                          id={`autocomplete${this.props.data.key}`}
                          classes={{ input: this.props.classes.autocompleteMultiInput }}
                          renderTags={(value: readonly string[], getTagProps) =>
                            value.map((option: string, index: number) => (
                              <Tooltip key={index} placement="top" title={this.toolTipCreate(option.toString())}>
                                <Chip size={'small'} label={option} {...getTagProps({ index })} />
                              </Tooltip>
                            ))
                          }
                          freeSolo
                          disableClearable
                          options={this.props.options}
                          disabled={this.disabled}
                          getOptionLabel={(option: string) => option || ''}
                          value={val.unique ? (val.dataValue === null ? 'null' : `${val.dataValue}`) : `${val}`}
                          isOptionEqualToValue={(option: string) =>
                            option === (val.unique ? (val.dataValue === null ? 'null' : val.dataValue) : val)
                          }
                          onChange={(_: ChangeEvent<unknown>, newValue: string | null) => {
                            const newValueObject = Object.entries(
                              this.state.value as { [key: string]: ValueObject },
                            ).reduce((acc: any, [k, v]) => {
                              if (key === k) {
                                if ('unique' in v) {
                                  acc[key] = { ...v, dataValue: newValue };
                                } else {
                                  acc[key] = v;
                                }
                              } else {
                                acc[k] = v;
                              }
                              return acc;
                            }, {});
                            this.onChange(newValueObject);
                          }}
                          onOpen={
                            !this.props.options && !this.state.tmpOptions.length
                              ? () => this.runValueChange('')
                              : undefined
                          }
                          renderInput={(params: any) => {
                            return (
                              <TextField
                                {...params}
                                id={this.props.data.key}
                                onChange={(e) => {
                                  const newValue = Object.entries(
                                    this.state.value as { [key: string]: ValueObject },
                                  ).reduce((acc: any, [k, v]) => {
                                    if (key === k) {
                                      if ('unique' in v) {
                                        acc[key] = { ...v, dataValue: e.target.value };
                                      } else {
                                        acc[key] = v;
                                      }
                                    } else {
                                      acc[k] = v;
                                    }
                                    return acc;
                                  }, {});
                                  this.onChange(newValue);
                                }}
                                error={this.state.error && !this.disabled}
                                helperText={this.state.helperText}
                                InputLabelProps={{
                                  shrink: true,
                                  htmlFor: `autocomplete${this.props.data.key}`,
                                  classes: { root: this.props.classes.labelBackground },
                                }}
                                variant="standard"
                                required={this.props.required && !this.props.data.readOnly}
                                fullWidth
                                onFocus={this.onFocus}
                                onBlur={this.onBlur}
                                autoFocus={this.props.data?.focus}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: !this.disabled ? (
                                    <InputAdornment position="end">
                                      <IconButton
                                        size="small"
                                        aria-label="close"
                                        onClick={() => {
                                          this.onChange(
                                            Object.fromEntries(
                                              Object.entries(this.state.value as { [key: string]: string }).filter(
                                                ([k]) => k !== key,
                                              ),
                                            ),
                                          );
                                          this.onBlur();
                                        }}
                                        onFocus={this.onFocus}
                                        onBlur={this.onBlur}
                                      >
                                        <Close />
                                      </IconButton>
                                    </InputAdornment>
                                  ) : undefined,
                                  id: `inputObject${this.props.data.key}`,
                                }}
                              />
                            );
                          }}
                        />
                      )}
                    </Grid>
                  </React.Fragment>
                );
              }
            })}
          </Grid>
        ) : (
          <div id={`inputObject${this.props.data.key}`} />
        )}
        {this.state.helperText ? <FormHelperText>{this.state.helperText}</FormHelperText> : null}
      </FormControl>
    );
  };

  select = (): React.ReactNode => {
    if (this.props.onValueChange) return this.selectOptions();
    return (
      <FormControl
        fullWidth
        variant="outlined"
        error={this.state.error && !this.disabled}
        disabled={this.disabled}
        required={this.props.required && !this.props.data.readOnly}
      >
        <InputLabel
          id={`select-label${this.props.data.key}`}
          htmlFor={`inputselect${this.props.data.key}`}
          shrink
          style={{ backgroundColor: 'white', paddingLeft: 4, paddingRight: 4, marginLeft: -4 }}
        >
          {this.label}
        </InputLabel>
        <Select
          id={`select${this.props.data.key}`}
          inputProps={{
            id: `inputselect${this.props.data.key}`,
            'aria-describedby': this.state.helperText ? `selecthelper${this.props.data.key}` : undefined,
          }}
          labelId={`select-label${this.props.data.key}`}
          value={this.state.value}
          onChange={(e) => this.onChange(e.target.value as any)}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
        >
          {(this.props.data.options || this.props.options || ([this.state.value] as string[])).map(
            (option: string | { label: string; value: any; description?: string; disabled?: boolean }) => (
              <MenuItem
                disabled={typeof option === 'object' ? (option.disabled ?? false) : false}
                value={typeof option === 'object' ? option.value : option}
                key={typeof option === 'object' ? option.value : option}
              >
                <div>
                  <div>{typeof option === 'object' ? option.label : option}</div>
                  {typeof option === 'object' && option?.description && (
                    <div style={{ color: '#696969' }}> {option?.description} </div>
                  )}
                </div>
              </MenuItem>
            ),
          )}
        </Select>
        {this.state.helperText ? (
          <FormHelperText id={`selecthelper${this.props.data.key}`}>{this.state.helperText}</FormHelperText>
        ) : null}
      </FormControl>
    );
  };

  selectOptions = (): React.ReactNode => {
    return (
      <Autocomplete
        id={`autocompleteSelect${this.props.data.key}`}
        classes={{ input: this.props.classes.autocompleteMultiInput }}
        ChipProps={{ size: 'small' }}
        options={this.state.tmpOptions}
        disabled={this.disabled}
        getOptionLabel={(option: string) => option}
        value={this.state.value as string}
        onChange={(_: ChangeEvent<unknown>, list: string | null) => this.onChange(list)}
        isOptionEqualToValue={(option: string, value: string) => option === value}
        onInputChange={!this.props.options ? this.onValueChange : undefined}
        onOpen={!this.props.options && !this.state.tmpOptions.length ? () => this.runValueChange('') : undefined}
        renderInput={(params: any) => (
          <TextField
            {...params}
            id={this.props.data.key}
            error={this.state.error && !this.disabled}
            helperText={this.state.helperText}
            InputLabelProps={{ shrink: true, htmlFor: `autocompleteSelect${this.props.data.key}` }}
            label={this.label}
            variant="outlined"
            required={this.props.required && !this.props.data.readOnly}
            fullWidth
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            autoFocus={this.props.data?.focus}
          />
        )}
      />
    );
  };

  json = (): React.ReactNode => {
    return (
      <>
        <FormControl
          key={this.props.data.key}
          disabled={this.disabled}
          className={clsx(this.props.classes.jsonFormControl, {
            'Mui-disabled': this.disabled,
            [this.props.classes.jsonFormControlError]: this.state.error || this.state.tmpError,
          })}
        >
          <FormLabel
            style={{ backgroundColor: '#FFF', padding: '0 4px' }}
            className={
              'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl' +
              ` MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined${
                this.state.error || this.state.tmpError ? ' Mui-error' : ''
              }`
            }
            required={this.props.required && !this.props.data.readOnly}
          >
            {this.label}
          </FormLabel>
          <JsonInputNative
            id="system"
            disabled={this.disabled}
            defaultValue={JSON.stringify(this.state.value, null, 2)}
            onChange={(data: any, error?: string) => {
              this.onChange(data, false, error ? true : false, error);
            }}
          />
        </FormControl>
        {this.state.helperText ? (
          <FormHelperText className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error Mui-required">
            {this.state.helperText}
          </FormHelperText>
        ) : null}
      </>
    );
  };

  // Might need fixing
  textarea = (): React.ReactNode => {
    return (
      <TextField
        id={this.props.data.key}
        key={this.props.data.key}
        fullWidth
        multiline
        type="string"
        variant="outlined"
        required={this.props.required && !this.props.data.readOnly}
        label={this.label}
        error={this.state.error && !this.disabled}
        helperText={this.state.helperText}
        InputLabelProps={{ shrink: true, classes: { root: this.props.classes.labelBackground } }}
        value={this.state.value as string}
        disabled={this.disabled}
        onChange={(e) => this.onChange(e.target.value)}
        InputProps={this.inputProps}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        autoFocus={this.props.data?.focus}
      />
    );
  };

  render = (): React.ReactNode => {
    if (
      (!!this.props.data.hiddenIn && this.props.data.hiddenIn.includes(this.props.state)) ||
      !this.props.data.type ||
      !this[this.props.data.type] ||
      (this.props.hideUnset && this.isUnset())
    )
      return null;
    if (this.disabled)
      return (
        <Grid key={this.props.objectKey} item xs={this.props.inputGrid || 12}>
          <SchemaFormRead
            type={
              this.props.data.variant && ['json', 'textarea'].includes(this.props.data.variant)
                ? (this.props.data.variant as 'json' | 'textarea')
                : this.props.data.type
            }
            page={this.props.data.page ? this.props.data.page : ""}
            message={this.props.data.message ? this.props.data.message : ""}
            standByText={this.props.data.standByText ? this.props.data.standByText : ""}
            value={this.getReadValue()}
            label={this.label}
            description={this.props.data.description}
            unit={(this.props.data as any).unit}
            isEditable={this.isEditable}
            navigate={this.props.navigate}
            default={this.props.default}
          />
        </Grid>
      );
    if (
      !!this.props.data.variant &&
      ((this.props.data.variant !== 'mapping' && this.props.data.type !== 'object') ||
        this.props.data.variant === 'json')
    )
      return (
        <Grid
          key={this.props.objectKey}
          item
          xs={this.props.inputGrid || 12}
          className={clsx({ [this.props.classes.editDisabled]: this.disabled })}
          style={{ position: 'relative' }}
        >
          <div style={{ position: 'relative' }}>
            {this.props.data.type !== 'boolean' && !!this.props.data.description ? (
              <Tooltip title={this.props.data.description} placement="left">
                <InfoOutlined
                  fontSize="small"
                  style={{
                    position: 'absolute' as const,
                    color: getTheme().palette.text.secondary,
                    cursor: 'pointer' as const,
                    top: -8,
                    right: 6,
                    backgroundColor: 'white',
                    display: 'block',
                    zIndex: 1,
                    borderRadius: '200%',
                  }}
                  className={clsx({
                    [this.props.classes.infoIconFocus]:
                      !this.disabled && this.state.hasFocus && !(this.state.error || this.state.tmpError),
                    [this.props.classes.infoIconError]: !this.disabled && (this.state.error || this.state.tmpError),
                  })}
                />
              </Tooltip>
            ) : undefined}
            {this[this.props.data.variant]()}
          </div>
        </Grid>
      );
    return (
      <Grid
        key={this.props.objectKey}
        item
        xs={this.props.inputGrid || 12}
        className={clsx({ [this.props.classes.editDisabled]: this.disabled })}
        style={{ position: 'relative' }}
      >
        <div style={{ position: 'relative' }}>
          {this.props.data.description ? (
            <Tooltip title={this.props.data.description} placement="left">
              <InfoOutlined
                fontSize="small"
                style={{
                  position: 'absolute' as const,
                  color: getTheme().palette.text.secondary,
                  cursor: 'pointer' as const,
                  top: -8,
                  right: 6,
                  backgroundColor: 'white',
                  display: 'block',
                  zIndex: 1,
                  borderRadius: '200%',
                }}
                classes={{
                  root: clsx({
                    [this.props.classes.infoIconFocus]:
                      !this.disabled && this.state.hasFocus && !(this.state.error || this.state.tmpError),
                    [this.props.classes.infoIconError]: !this.disabled && (this.state.error || this.state.tmpError),
                  }),
                }}
              />
            </Tooltip>
          ) : undefined}
          {this[this.props.data.type]()}
        </div>
      </Grid>
    );
  };
}

export const SchemaFormInput = withStyles(SchemaFormInputStyles)(_SchemaFormInput);
