import React from 'react';
import { connect } from 'react-redux';
import { MenuItem } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { withRouter } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { DeviceService } from '../../../../services';
import { Dialog, TabsHeader } from '../../../../components';
import DevicePropertiesTab from '../DevicePropertiesTab';
import ContractTab from "./ContractTab";
import ContractHistoryTab from "./ContractHistoryTab";
import FirmwareTab from '../FirmwareTab';
import ShadowTab from './ShadowTab';
import DeviceGroupsTab from './DeviceGroupsTab';
import ConnectionTab from './ConnectionTab';
import ListenTab from './ListenTab';
import { DeviceTypes, DeviceState, LastVisited, ShadowAlias, Store } from '../../../../common/types';
import { itemIcons } from '../../../../common/constants';
import {
  ViewBase,
  ViewBaseStyles,
  ViewBaseProps,
  ViewBaseState,
  ViewBaseMapDispatchToProps,
} from '../../../baseClasses/ViewBase';

type _Props = {
  shadowAliases: ShadowAlias[];
  addToLastVisited: (lastVisited: LastVisited) => void;
};
type Props = ViewBaseProps<_Props, { deviceId: string }>;

type _State = unknown & { deviceTypes: DeviceTypes[] };
type State = ViewBaseState<_State>;

class DeviceDetailedView extends ViewBase<_Props, _State, { deviceId: string }> {
  state: State = {
    loading: false,
    activeTab: 0,
    item: undefined,
    deleteDialogOpen: false,
    deviceTypes: [],
  };

  tabsNoShadow = ['tab.properties', 'tab.deviceGroups', 'tab.firmware', 'tab.connections', 'tab.traffic'];
  tabsNoFirmware = ['tab.properties', 'tab.deviceGroups', 'tab.shadow', 'tab.connections', 'tab.traffic'];
  tabsNoShadowNoFirmware = ['tab.properties', 'tab.deviceGroups', 'tab.connections', 'tab.traffic'];

  constructor(props: Props) {
    super(props);
    this.tabs = ['tab.properties', 'tab.deviceGroups', 'tab.firmware', 'tab.shadow', 'tab.connections', 'tab.traffic', 'tab.contract', 'tab.contractHistory'];

    DeviceService.create().then(async (service) => {
      const { data } = await service.deviceType.list();
      this.setState({ deviceTypes: data.data });
      return (this.service = service);
    });
    this.subservice = 'device';
    this.messages = {
      load: { error: 'error.fetch.device' },
      update: { success: 'success.update.device', error: 'error.tryAgain' },
      delete: { success: 'success.delete.device', error: 'error.tryAgain' },
    };
  }

  toggleBetweebActiveDeactive = async (): Promise<void> => {
    this.setState({ loading: true });
    await this.update({
      state: DeviceState.activated === this.state.item!.state ? DeviceState.deactivated : DeviceState.activated,
    });
    this.setState({ loading: false });
  };

  getTabs = (): string[] => {
    const active = [this.tabs[0], this.tabs[1], this.tabs[6], this.tabs[7]];
    const tabNames = {
      FIRMWARE: ['tab.firmware'],
      CONNECTION: ['tab.traffic', 'tab.connections'],
      SHADOW: ['tab.shadow'],
    };

    const dt = this.state.deviceTypes.find((dt) => dt.id === this.state.item.type);
    if (dt && !dt.capabilities) {
      return active;
    } else if (dt && dt?.capabilities) {
      let n = active;
      dt?.capabilities.forEach((c) => (n = [...n, ...tabNames[c]]));
      return n;
    } else {
      return this.tabs;
    }
  };

  renderTabsHeaderMenuItems = (type?: boolean): React.ReactNode[] | undefined => {
    const list: React.ReactNode[] = [];
    if (!this.accessControl) return undefined;
    if (this.accessControl('device', 'update'))
      list.push(
        <MenuItem
          key="1"
          onClick={this.toggleBetweebActiveDeactive}
          disabled={
            !this.state.item ||
            (this.state.item && ![DeviceState.activated, DeviceState.deactivated].includes(this.state.item.state))
          }
        >
          <Trans>
            {this.state.item && DeviceState.activated === this.state.item.state ? 'Deactivate' : 'Activate'}
          </Trans>
        </MenuItem>,
      );
    if (this.accessControl('device', 'delete'))
      list.push(
        <MenuItem key="2" disabled={!this.state.item} onClick={() => this.setState({ deleteDialogOpen: true })}>
          <Trans>action.delete</Trans>
        </MenuItem>,
      );
    if (type)
      list.push(
        <MenuItem key="3" disabled={!this.state.item} onClick={() => console.log('change type')}>
          <Trans>action.changeType</Trans>
        </MenuItem>,
      );
    return list;
  };

  renderTabs = (): React.ReactNode => (
    <>
      <DevicePropertiesTab
        tab={0}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="device"
        parentLoading={this.state.loading}
        item={this.state.item}
        update={this.update}
        reload={this.load}
      />
      <DeviceGroupsTab
        tab={1}
        activeTab={this.state.activeTab}
        parentLoading={this.state.loading}
        deviceGroupIds={(this.state.item && this.state.item.groups) || []}
        accessControl={this.accessControl}
        reload={this.load}
      />
      <FirmwareTab
        tab={2}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        accessControlSection="device"
        parentLoading={this.state.loading}
        item={this.state.item}
        update={this.update}
        reload={this.load}
      />
      <ShadowTab
        tab={3}
        activeTab={this.state.activeTab}
        parentLoading={this.state.loading}
        accessControl={this.accessControl}
      />
      <ConnectionTab
        tab={4}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
      />
      <ListenTab
        tab={5}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
      />
      <ContractTab
        tab={6}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
        // customAction={
        //   <Button
        //     size="small"
        //     color="secondary"
        //     disabled={!this.state.item || !this.state.item.url}
        //     onClick={this.download}
        //   >
        //     {i18next.t('action.download')}
        //   </Button>
        // }
      />
      <ContractHistoryTab
        tab={7}
        activeTab={this.state.activeTab}
        accessControl={this.accessControl}
        parentLoading={this.state.loading}
      />
    </>
  );

  renderDialogs = (): React.ReactNode => (
    <Dialog
      open={this.state.deleteDialogOpen}
      title={<Trans>action.delete</Trans>}
      description={<Trans>description.delete.device</Trans>}
      continueTitle={<Trans>action.delete</Trans>}
      onClose={() => this.setState({ deleteDialogOpen: false })}
      onContinue={this.delete}
    />
  );

  render = (): React.ReactNode => (
    <div className={this.props.classes.root}>
      <TabsHeader
        title={this.props.match.params.deviceId}
        icon={itemIcons.device}
        device={this.state.item}
        subtitle={
          this.state.item
            ? `${this.state.item.name ? this.state.item.name : ''} ${this.state.item.description ? `|` : ''} ${
                this.state.item.description ? this.state.item.description : ''
              }`
            : ''
        }
        tabIndex={this.state.activeTab}
        onChangeTab={this.changeTab}
        tabs={this.tabs}
        activeTabs={this.state.item && this.getTabs()}
        menuItems={this.renderTabsHeaderMenuItems()}
      >
        {this.renderTabs()}
      </TabsHeader>
      {this.renderDialogs()}
    </div>
  );
}

const mapStateToProps = ({ deviceStore }: Store) => ({
  shadowAliases: deviceStore.shadowAliases,
});

export default withStyles(ViewBaseStyles)(
  withRouter(connect(mapStateToProps, ViewBaseMapDispatchToProps)(DeviceDetailedView)),
);
