export enum DeviceState {
  created = 'CREATED',
  activated = 'ACTIVATED',
  deactivated = 'DEACTIVATED',
  revoked = 'REVOKED',
}

export interface DeviceConnection {
  connected: string;
  disconnected?: string;
  clientInitiatedDisconnect?: boolean;
  disconnectReason?: string;
}

export interface PostDevice {
  certificateId: string;
  name?: string;
  description?: string;
}

export interface Device extends Omit<PostDevice, 'name' | 'description'> {
  deviceId: string;
  certificateCN: string;
  thingName: string;
  state: DeviceState;
  name: string;
  imei: string;
  imsi: string;
  lastSeen: string;
  description: string;
  created: string;
  contract: string;
  activated: string;
  groups: string[];
}

export interface DeviceDetailed extends Device {
  customerId: string;
  version: string;
}
export type Capabilities = 'SHADOW' | 'FIRMWARE' | 'CONNECTION';
export interface DeviceTypes {
  id: string;
  name: string;
  description?: string;
  capabilities?: Capabilities[] | null;
}

export interface DeviceHistories {
  user: string;
  events: string;
  eventDate?: string;
}

export interface PostDeviceGroup {
  groupId: string;
  description?: string;
  parent?: string;
}

export interface DeviceGroup extends PostDeviceGroup {
  groupType: string;
  policyType: string;
}

export interface PostTriggers {
  triggerId: string;
}

export interface Triggers extends PostTriggers {
  triggerId: string;
}

export type DeviceGroupDetailed = DeviceGroup;

export interface PostShadowAlias {
  alias: string;
  description?: string;
  property: string;
}

export interface ShadowAlias extends PostShadowAlias {
  isTimestamp: boolean;
  modified?: string;
  created: string;
}

export interface PostShadowTemplate {
  templateId: string;
  description?: string;
  template: { [key: string]: any };
}
export interface ShadowTemplate extends Omit<PostShadowTemplate, 'template'> {
  default: boolean;
  modified?: string;
  created: string;
}
export interface ShadowTemplateDetailed extends ShadowTemplate {
  template: { [key: string]: any };
  modified?: string;
  created: string;
}
