import React, { Component } from 'react';
import { Dialog as MDialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import i18next from 'i18next';

type DialogProps = {
  open: boolean;
  title?: string | React.ReactElement;
  description?: string | React.ReactElement;
  cancelTitle?: string | React.ReactElement;
  continueTitle?: string | React.ReactElement;
  isShowContinueButton?: boolean;
  isShowCancelButton?: boolean;
  onClose: () => void;
  onCancel?: () => void;
  onContinue: () => void;
  disabled?: boolean;
  children?: React.ReactNode;
};

export class Dialog extends Component<DialogProps> {
  static defaultProps = {
    isShowCancelButton: true,
    isShowContinueButton: true
  };

  cancel = (): void => {
    const { onClose, onCancel } = this.props;
    if (onCancel) onCancel();
    onClose();
  };

  continue = async (): Promise<void> => {
    const { onClose, onContinue } = this.props;
    if (!onContinue) onClose();
    try {
      await onContinue();
      onClose();
    } catch {
      console.error('Dialog.tsx in continue');
    }
  };

  render = () => {
    const { open, title, description, cancelTitle, continueTitle, isShowCancelButton, isShowContinueButton } = this.props;
    return (
      <MDialog open={open} onClose={this.cancel}>
        {title ? <DialogTitle>{title}</DialogTitle> : null}
        {description ? (
          <DialogContent>
            <DialogContentText>{description}</DialogContentText>
            {this.props.children}
          </DialogContent>
        ) : null}
        <DialogActions>
          {isShowCancelButton && (
            <Button onClick={this.cancel} color="secondary">
              {typeof cancelTitle === 'string' || !cancelTitle ? i18next.t(cancelTitle || 'action.cancel') : cancelTitle}
            </Button>
          )}
          {isShowContinueButton && (
            <Button disabled={this.props.disabled} onClick={this.continue} variant="contained" color="secondary">
              {typeof continueTitle === 'string' || !continueTitle
                  ? i18next.t(continueTitle || 'action.continue')
                  : continueTitle}
            </Button>
          )}
        </DialogActions>
      </MDialog>
    );
  };
}
