import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import i18next from 'i18next';
import { DeviceHistories } from '../../../../common/types';
import { ListTable, TabPanel } from '../../../../components';
import { tableProperties, TableNames } from '../../../../common/constants';
import { getDefaultTableProperties, DeviceService } from '../../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';

type Props = RouteComponentProps<{ deviceId: string }>;

type State = {
  histories: DeviceHistories[];
  initLoaded: boolean;
};

class ContractHistoryTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    histories: [],
    initLoaded: false,
  };

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    DeviceService.create().then((service) => (this.service = service));
  }

  reload = (): void =>
    this.setState({ histories: [], initLoaded: false }, () =>
      this.setState({ initLoaded: true }),
    );

  load = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      const { data: history } = await this.service.device.getContractHistory({
        deviceId: this.props.match.params.deviceId,
      });
  
      const formattedHistory = history.map((item: { eventDate: string | number | Date; }) => {
        const eventDate = new Date(item.eventDate);
  
        const hours = eventDate.getHours().toString().padStart(2, '0');
        const minutes = eventDate.getMinutes().toString().padStart(2, '0');
  
        const formattedDate = eventDate.toLocaleDateString('en-GB', {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        });
  
        // 将时分放在日期的前面
        const formattedEventDate = `${hours}:${minutes}, ${formattedDate}`;
  
        return {
          ...item,
          eventDate: formattedEventDate,
        };
      });
  
      this.setState({
        loading: false,
        histories: this.state.histories!.concat(formattedHistory),
      });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) ||
          i18next.t('error.fetch.deviceContractHistories'),
      );
      this.setState({ loading: false });
    }
  };

  renderContent = (): React.ReactNode => (
    <ListTable
      InfinityScrollProps={{
        initLoaded: this.state.initLoaded,
        loadFunc: this.load,
      }}
      TableProps={{ disableBackgroundPaper: true }}
      loading={this.state.loading}
      emptyTitle="form.empty.deviceContractHistories"
      properties={getDefaultTableProperties(tableProperties(TableNames.deviceContractHistory))}
      rows={this.state.histories}
    />
  );

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.state.loading,
        },
      }}
    >
      {this.renderContent()}
    </TabPanel>
  );
}

export default withRouter(ContractHistoryTab);
