import React from 'react';
import { FilterCombination, BaseFilterCombination, ListTableProperty } from '../common/types';
import { tableProperties, TableProperty, TableNames } from '../common/constants';
import { createUrlFromFilter } from '../common/filterUtils';

const localStorageNameDevice = 'device-filter-v4';
const localStorageNameDeviceGroup = 'device-group-filter-v4';
const localStorageNameShadowTemplate = 'shadow-template-filter-v4';
const localStorageNameUser = 'user-filter-v4';
const localStorageNamePackage = 'package-filter-v4';
const localStorageNameProduct = 'product-filter-v4';
const localStorageNameRelease = 'release-filter-v4';

type FilterProperties = 'device' | 'deviceGroup' | 'shadowTemplate' | 'user' | 'package' | 'product' | 'release';

export function getDefaultTableProperties(listTableProperty: TableProperty): TableProperty {
  return Object.entries(listTableProperty).reduce((acc: TableProperty, [key, value]: [string, ListTableProperty]) => {
    if (value.defaultActive) acc[key] = value;
    return acc;
  }, {});
}

export function getDefaultTablePropertiesAsString(listTableProperty: TableProperty): string {
  return Object.entries(listTableProperty)
    .filter(([, value]) => value.defaultActive)
    .map(([key]) => key)
    .join(',');
}

export class FilterService {
  storage: string;
  defaults: FilterCombination[];

  constructor(public component: React.Component, protected filterName: FilterProperties) {
    const config = filterServiceConfig[filterName];
    this.storage = config.storage;
    this.defaults = config.defaults;
  }

  get defaultProperties(): string {
    return this.defaults[0].properties;
  }

  get = (): FilterCombination[] => {
    const storage = localStorage.getItem(this.storage);

    if (storage) {
      const parsedStorage = JSON.parse(storage);
      parsedStorage.forEach((item: any) => {
        if (item.properties && item.properties.includes("state")) {
          item.properties = item.properties.replace("state", "contract");
        }

        if (item.value && item.value.includes("state")) {
          item.value = item.value.replace("state", "contract");
        }
      });
      localStorage.setItem(this.storage, JSON.stringify(parsedStorage));
      const filters = parsedStorage ? parsedStorage : this.defaults;
      const { search } = window.location;
  
      if (search && filters.findIndex((pf: FilterCombination) => pf.value === search) === -1) {
        const filter = new URLSearchParams(search);
        filters.push({
          id: Math.random().toString(36).substring(2, 9),
          label: filter.get('queryLabel') || 'New query',
          value: search,
          properties: filter.get('queryTableProperties') || '',
          temporary: true,
        });
      }
  
      return filters;
    } else {
      localStorage.setItem(this.storage, JSON.stringify(this.defaults));
      const filters = storage ? JSON.parse(storage) : this.defaults;
      const { search } = window.location;
  
      if (search && filters.findIndex((pf: FilterCombination) => pf.value === search) === -1) {
        const filter = new URLSearchParams(search);
        filters.push({
          id: Math.random().toString(36).substring(2, 9),
          label: filter.get('queryLabel') || 'New query',
          value: search,
          properties: filter.get('queryTableProperties') || '',
          temporary: true,
        });
      }
  
      return filters;
    }
  };

  getIndex = () => {
    const index = this.get().findIndex((pf: FilterCombination) => pf.value === window.location.search);
    if (index === -1) return 0;
    return index;
  };

  post = async ({ properties, ...filterCombination }: BaseFilterCombination): Promise<FilterCombination[]> => {
    const data = localStorage.getItem(this.storage);
    if (!data) throw new Error();
    const filterCombinations = JSON.parse(data) as FilterCombination[];
    const fc = {
      id: Math.random().toString(36).substring(2, 9),
      properties: properties || this.defaults[0].properties,
      ...filterCombination,
    };
    filterCombinations.push(fc);
    localStorage.setItem(this.storage, JSON.stringify(filterCombinations));
    return Promise.resolve(filterCombinations);
  };

  put = async (filterCombination: FilterCombination): Promise<FilterCombination[]> => {
    const data = localStorage.getItem(this.storage);
    if (!data) throw new Error();
    const filterCombinations = JSON.parse(data) as FilterCombination[];
    const index = filterCombinations.findIndex((fc) => fc.id === filterCombination.id);
    if (index > -1) {
      filterCombinations[index].label = filterCombination.label;
      filterCombinations[index].value = filterCombination.value;
      filterCombinations[index].properties = filterCombination.properties;
      localStorage.setItem(this.storage, JSON.stringify(filterCombinations));
      return Promise.resolve(filterCombinations);
    }
    return Promise.reject('Filter not found');
  };

  del = async (id: string): Promise<FilterCombination[]> => {
    const data = localStorage.getItem(this.storage);
    if (!data) throw new Error();
    const filterCombinations = JSON.parse(data) as FilterCombination[];
    const filterCombination = filterCombinations.find((fc) => fc.id === id);
    if (!filterCombination) throw new Error();
    let newFilterCombinations = filterCombinations.filter((fc) => fc.id !== id);
    if (!newFilterCombinations.length) newFilterCombinations = JSON.parse(JSON.stringify(this.defaults));
    localStorage.setItem(this.storage, JSON.stringify(newFilterCombinations));
    return Promise.resolve(newFilterCombinations);
  };

  createFilter = async (fc: BaseFilterCombination): Promise<void> => {
    const predefinedFilters = await this.post(fc);
    this.component.setState({ predefinedFilters, activeTabIndex: predefinedFilters.length - 1 });
  };

  createTemporaryFilter = async (fc?: BaseFilterCombination): Promise<void> => {
    const predefinedFilters = (this.component.state as any).predefinedFilters.slice();
    predefinedFilters.push({
      id: Math.random().toString(36).substring(2, 9),
      label: (fc && fc.label) || 'New query',
      value: (fc && fc.value) || '',
      properties: (fc && fc.properties) || this.defaultProperties,
      temporary: true,
    });
    this.component.setState({ predefinedFilters, activeTabIndex: predefinedFilters.length - 1 });
  };

  updateFilter = async (fc: FilterCombination): Promise<void> => {
    const predefinedFilters = await this.put(fc);
    this.component.setState({ predefinedFilters });
  };

  deleteFilter = async (id: string): Promise<void> => {
    return new Promise(async (resolve) => {
      const predefinedFilters = await this.del(id);
      this.component.setState(
        {
          predefinedFilters,
          activeTabIndex:
            predefinedFilters.length - 1 <= (this.component.state as any).activeTabIndex &&
            (this.component.state as any).activeTabIndex > 0
              ? (this.component.state as any).activeTabIndex - 1
              : (this.component.state as any).activeTabIndex,
        },
        resolve,
      );
    });
  };

  cancelTemporary = (): Promise<void> => {
    return new Promise(async (resolve) => {
      this.component.setState(
        {
          predefinedFilters: await this.get(),
          activeTabIndex:
            (this.component.state as any).predefinedFilters.length - 1 ===
              (this.component.state as any).activeTabIndex && (this.component.state as any).activeTabIndex !== 0
              ? (this.component.state as any).predefinedFilters.length - 2
              : (this.component.state as any).activeTabIndex,
        },
        resolve,
      );
    });
  };
}

export const filterServiceConfig = {
  device: {
    storage: localStorageNameDevice,
    defaults: [
      {
        id: '1',
        label: 'All',
        value: createUrlFromFilter(
          [{ type: 'string', property: 'query', operator: 'startsWith', value1: '', value2: '' }],
          'All',
          getDefaultTablePropertiesAsString(tableProperties(TableNames.device)),
        ),
        properties: getDefaultTablePropertiesAsString(tableProperties(TableNames.device)),
      },
    ],
  },
  deviceGroup: {
    storage: localStorageNameDeviceGroup,
    defaults: [
      {
        id: '1',
        label: 'All',
        value: createUrlFromFilter(
          [{ type: 'string', property: 'parent', operator: 'startsWith', value1: '', value2: '' }],
          'All',
          getDefaultTablePropertiesAsString(tableProperties(TableNames.deviceGroup)),
        ),
        properties: getDefaultTablePropertiesAsString(tableProperties(TableNames.deviceGroup)),
      },
    ],
  },
  shadowTemplate: {
    storage: localStorageNameShadowTemplate,
    defaults: [
      {
        id: '1',
        label: 'All',
        value: createUrlFromFilter(
          [{ type: 'string', property: 'templateId', operator: 'startsWith', value1: '', value2: '' }],
          'All',
          getDefaultTablePropertiesAsString(tableProperties(TableNames.shadowTemplate)),
        ),
        properties: getDefaultTablePropertiesAsString(tableProperties(TableNames.shadowTemplate)),
      },
    ],
  },
  user: {
    storage: localStorageNameUser,
    defaults: [
      {
        id: '1',
        label: 'All',
        value: createUrlFromFilter(
          [{ type: 'string', property: 'email', operator: 'startsWith', value1: '', value2: '' }],
          'All',
          getDefaultTablePropertiesAsString(tableProperties(TableNames.user)),
        ),
        properties: getDefaultTablePropertiesAsString(tableProperties(TableNames.user)),
      },
    ],
  },
  product: {
    storage: localStorageNameProduct,
    defaults: [
      {
        id: '1',
        label: 'All',
        value: createUrlFromFilter(
          [{ type: 'string', property: 'productId', operator: 'startsWith', value1: '', value2: '' }],
          'All',
          getDefaultTablePropertiesAsString(tableProperties(TableNames.product)),
        ),
        properties: getDefaultTablePropertiesAsString(tableProperties(TableNames.product)),
      },
    ],
  },
  release: {
    storage: localStorageNameRelease,
    defaults: [
      {
        id: '1',
        label: 'All',
        value: createUrlFromFilter(
          [{ type: 'string', property: 'releaseId', operator: 'startsWith', value1: '', value2: '' }],
          'All',
          getDefaultTablePropertiesAsString(tableProperties(TableNames.release)),
        ),
        properties: getDefaultTablePropertiesAsString(tableProperties(TableNames.release)),
      },
    ],
  },
  package: {
    storage: localStorageNamePackage,
    defaults: [
      {
        id: '1',
        label: 'All',
        value: createUrlFromFilter(
          [{ type: 'string', property: 'productId', operator: 'equal', value1: '', value2: '' }],
          'All',
          getDefaultTablePropertiesAsString(tableProperties(TableNames.package)),
        ),
        properties: getDefaultTablePropertiesAsString(tableProperties(TableNames.package)),
      },
    ],
  },
};
