import React from 'react';
import { WithStyles } from '@mui/styles';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import i18next from 'i18next';
import { LastVisited, RoutePathPrefix } from '../../common/types';
import { SnackbarUtils } from '../../components';
import { storeAddLastVisited } from '../../store/actions/common';
import { AccessControl, AccessControlSectionAction } from '../../common/permissions';
import { AuthenticationServiceProvider } from '../../services';
import { getTheme } from '../../common/theme';

export const ViewBaseStyles = () => {
  const theme = getTheme();
  return {
    root: {
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2),
      },
    },
    fabContainer: {
      float: 'right' as const,
      marginRight: theme.spacing(-2),
      width: 56,
    },
    fab: {
      position: 'fixed' as const,
      bottom: theme.spacing(4),
    },
  };
};

type _MessageState = { success: string; error: string };
type Messages = { load: Omit<_MessageState, 'success'>; update: _MessageState; delete: _MessageState };

// IMPORTANT! Only one attribute in RouteComponentProps is allowed
type _ViewBaseProps<R extends { [K in keyof R]?: string | undefined }> = RouteComponentProps<R> &
  WithStyles<typeof ViewBaseStyles> & {
    addToLastVisited: (lastVisited: LastVisited, origin?: string) => void;
  };
export type ViewBaseProps<P = unknown, R = unknown> = _ViewBaseProps<R> & Omit<P, keyof _ViewBaseProps<R>>;

type _ViewBaseState = {
  loading: boolean;
  activeTab: number;
  item: any;
  deleteDialogOpen: boolean;
};
export type ViewBaseState<S = unknown> = _ViewBaseState & Omit<S, keyof _ViewBaseState>;

export class ViewBase<P = unknown, S = unknown, R = unknown> extends React.Component<
  ViewBaseProps<P, R>,
  ViewBaseState<S>
> {
  isUnmounted = false;
  id: string;

  private _accessControl?: AccessControl;
  tabs!: string[];

  authenticationServiceProvider?: AuthenticationServiceProvider;

  service!: any;
  subservice!: string;
  messages!: Messages;

  constructor(props: ViewBaseProps<P, R>) {
    super(props);
    this.id = Object.values(this.props.match.params)[0];
    AuthenticationServiceProvider.createFromCache().then((service) => {
      if (service) {
        this.authenticationServiceProvider = service;
      }
      this._accessControl = new AccessControl(service!.getRole()!);
      this.forceUpdate();
    });
  }

  componentDidMount = (): void => {
    let activeTab = parseInt(new URLSearchParams(window.location.search).get('tab') || '0', 10);
    activeTab = activeTab < this.tabs.length ? activeTab : 0;
    this.changeTab(activeTab, true);
    this.load();
  };

  UNSAFE_componentWillUpdate = (nextProps: ViewBaseProps<P, R>, nextState: ViewBaseState<S>): void => {
    if (this.state.item === undefined && nextState.item !== this.state.item)
      this.props.addToLastVisited(nextState.item, this.authenticationServiceProvider?.service.userId());
  };

  componentDidUpdate = (prevProps: ViewBaseProps<P, R>): void => {
    if (Object.values(prevProps.match.params)[0] !== Object.values(this.props.match.params)[0])
      this.componentDidMount();
  };

  componentWillUnmount = (): void => {
    this.isUnmounted = true;
  };

  accessControl = (section: string, action: AccessControlSectionAction): boolean => {
    if (!this._accessControl) return false;
    return this._accessControl!.access(section)[action]();
  };

  changeTab = (activeTab: number, force = false): Promise<void> => {
    if (activeTab !== this.state.activeTab || force)
      window.history.replaceState({ tab: activeTab }, '', `${window.location.pathname}?tab=${activeTab}`);
    return new Promise((resolve) => this.setState({ activeTab } as ViewBaseState<S>, resolve));
  };

  load = async (waited = 25): Promise<void> => {
    if (!this.state.loading) this.setState({ loading: true } as ViewBaseState<S>);
    if (!this.service) {
      if (waited < 100) setTimeout(() => this.load(waited++), waited);
      else SnackbarUtils.error(i18next.t(this.messages.load.error));
      return;
    }
    try {
      const { data } = await this.service[this.subservice].get(this.id);
      if (!this.isUnmounted) this.setState({ item: data, loading: false } as ViewBaseState<S>);
    } catch (e) {
      const error: any = e;
      if (!!error.response && error.response.status === 404)
        SnackbarUtils.error(
          (error.response && error.response.data && error.response.data.message) || i18next.t(this.messages.load.error),
        );
      if (!this.isUnmounted) this.setState({ loading: false } as ViewBaseState<S>);
    }
  };

  update = async (properties: any): Promise<void> => {
    try {
      const { data } = await this.service[this.subservice].put(this.id, properties);
      this.setState({ item: { ...this.state.item, ...data } });
      SnackbarUtils.success(i18next.t(this.messages.update.success));
    } catch (e) {
      console.log(e)
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t(this.messages.update.error),
      );
    }
  };

  delete = async (): Promise<void> => {
    try {
      await this.service[this.subservice].del(this.id);
      SnackbarUtils.success(i18next.t(this.messages.delete.success));
      this.props.history.goBack();
    } catch (e) {
      const error: any = e;
      if (error.response && error.response.status === 403) {
        SnackbarUtils.error(i18next.t('error.deletePermisson'));
      } else {
        SnackbarUtils.error(
          (error.response && error.response.data && error.response.data.message) ||
            i18next.t(this.messages.delete.error),
        );
      }
    }
  };

  // Override these
  renderTabsHeaderButtons = (): React.ReactNode[] | undefined => undefined;
  renderTabsHeaderMenuItems = (): React.ReactNode[] | undefined => undefined;
  renderTabs!: () => React.ReactNode;
  renderDialogs = (): React.ReactNode => undefined;
}

export const ViewBaseMapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  addToLastVisited: (lastVisited: LastVisited, origin?: string) =>
    dispatch(storeAddLastVisited({ ...lastVisited, origin }, RoutePathPrefix.operations)),
});
