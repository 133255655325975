import React from 'react';
import Papa from 'papaparse';
import withStyles from '@mui/styles/withStyles';
import { DropzoneArea } from "mui-file-dropzone";
import { withRouter } from 'react-router-dom';
import { Dispatch, Action } from 'redux';
import { connect } from 'react-redux';
import i18next from 'i18next';
import { DeviceService, FilterService } from '../../../services';
import { Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import {
  SubdomainRoutePath,
  ListTableProperty,
  ModalVariants,
  Store,
  ShadowAlias,
  PropertyType,
  ListFilterProperty,
  ListFilterOperator,
  Device,
  DeviceState,
} from '../../../common/types';
import { tableProperties, listFilter, itemIcons, TableNames } from '../../../common/constants';
import { storeOpenModal } from '../../../store/actions/modal';
import { ListBase, ListBaseStyles, ListBaseProps, ListBaseState } from '../../baseClasses/ListBase';
import { SnackbarUtils } from '../../../components';
import * as filterUtils from '../../../common/filterUtils';

type _Props = {
  shadowAliases: ShadowAlias[];
  openModal: (callback: () => any) => void;
};
type Props = ListBaseProps<_Props>;

type State = ListBaseState;

class DeviceView extends ListBase<_Props> {
  state: State = {
    initLoaded: false,
    loading: false,
    items: [],
    activeTabIndex: 0,
    predefinedFilters: [],
    properties: [],
    importFiles: [],
    exportDataType: "1",
    checkResult: {},
    confirmContent: "",
    csvKey: "",
  };

  constructor(props: Props) {
    super(props);
    // Init static variables
    this.filterService = new FilterService(this, 'device');
    this.accessControlSection = 'device';
    this.title = i18next.t('devices');
    this.icon = itemIcons.device;
    this.idKey = 'deviceId';
    this.idKey = 'deviceId';
    this.filterConfig = {
      // Merges standard list filters with shadow alias so they can be filtered on
      listFilter: listFilter.device.concat(
        this.props.shadowAliases.reduce((acc: ListFilterProperty[], val: ShadowAlias) => {
          if (val.isTimestamp) {
            acc.push({
              property: `alias.${val.alias}`,
              displayName: val.alias,
              type: 'date' as PropertyType,
              operators: ['between', 'betweenAbsolute'] as ListFilterOperator[],
            } as ListFilterProperty);
          } else {
            acc.push({
              property: `alias.${val.alias}`,
              displayName: val.alias,
              type: 'string' as PropertyType,
              operators: ['startsWith', 'equal'] as ListFilterOperator[],
            } as ListFilterProperty);
          }
          return acc;
        }, []) as any,
      ),
      availableProperties: Object.keys(tableProperties(TableNames.device)).concat(
        (this.props.shadowAliases || [])
          .filter((sa) => !['firmwareUpdated', 'firmwareVersion'].includes(sa.alias))
          .map((sa) => sa.alias),
      ),
    };
    this.tableConfig = {
      // Merges standard table props with shadow alias props so they can be shown in list
      tableProperties: {
        ...tableProperties(TableNames.device),
        ...(this.props.shadowAliases.reduce((acc: any, val: ShadowAlias) => {
          if (val.isTimestamp) {
            acc[val.alias] = { defaultActive: true, displayName: val.alias, displayType: 'dateTime' };
          } else {
            acc[val.alias] = { defaultActive: true, displayName: val.alias, displayType: 'text' };
          }
          return acc;
        }, {}) as { [key: string]: ListTableProperty }),
      },
      emptyTitle: 'form.empty.devices',
      rowActions: [
        // {
        //   title: 'action.activate',
        //   dynamicDisabled: (device: Device) => ![DeviceState.activated, DeviceState.deactivated].includes(device.state),
        //   dynamicTitle: (device: Device) =>
        //     device.state === DeviceState.activated ? 'action.deactivate' : 'action.activate',
        //   action: async (device: Device) => await this.toggleBetweebActiveDeactive(device.deviceId),
        // },
        // {
        //   title: 'action.delete',
        //   action: async (device: Device) => this.setState({ dialogOpen: device.deviceId }),
        // },
          {
            title: 'action.contractActive',
            dynamicDisabled: (device: Device) => ["Active", "Terminate"].includes(device.contract),
            action: async (device: Device) => {this.updateContractStatus(device.deviceId, "Active")},
          },
          {
            title: 'action.contractPause',
            dynamicDisabled: (device: Device) => ["Pause", "Standby to Pause", "Terminate"].includes(device.contract),
            action: async (device: Device) => this.updateContractStatus(device.deviceId, "Standby to Pause"),
          },
          {
            title: 'action.contractTerminate',
            dynamicDisabled: (device: Device) => ["Standby to Terminate", "Terminate"].includes(device.contract),
            action: async (device: Device) => this.setState({ dialogOpen: device.deviceId }),
          },
      ],
    };

    this.exportDialogConfig = {
      title: i18next.t('action.export'),
      description: (
        <div style={{width: '500px'}}>
          <FormControl>
            <FormLabel>Export device list</FormLabel>
            <RadioGroup defaultValue={this.state.exportDataType} onChange={this.handleChange}>
              <FormControlLabel value="1" control={<Radio />} label="Export ALL devices" />
              <FormControlLabel value="2" control={<Radio />} label="Export the found devices" />
            </RadioGroup>
          </FormControl>
        </div>
      ),
      continueTitle: i18next.t('action.exportToCsv'),
      onClose: () => this.setState({ exportDialogOpen: undefined }),
      onContinue: () => this.state.exportDialogOpen && this.exportDeviceList(),
      onOpen: () => this.setState({ exportDialogOpen: true }),
    };

    this.importDialogConfig = {
      title: i18next.t('action.import'),
      description: (
        <div style={{width: '500px'}}>
          <div>{i18next.t('description.import.importDeviceList')}</div>
          <div>
            <DropzoneArea
              onChange={this.uploadFile}
              onDelete={() => {this.setState({ importFiles: [] })}}
              fileObjects={this.state.importFiles}
              showPreviews={true}
              showPreviewsInDropzone={false}
              useChipsForPreview
              previewGridProps={{container: { spacing: 1, direction: 'row' }}}
              previewText="Selected files"
              filesLimit={1}
              acceptedFiles={["text/csv"]}
              dropzoneText={i18next.t('description.import.uploadCsvFile')}
              maxFileSize={5000000}
              alertSnackbarProps={{
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' }
              }}
            />
          </div>
        </div>
      ),
      continueTitle: i18next.t('action.uploadFile'),
      onClose: () => this.setState({ importDialogOpen: undefined }),
      onContinue: () => this.state.importDialogOpen && this.importDeviceList(),
      onOpen: () => this.setState({ importDialogOpen: true }),
    };

    this.importConfirmDialogConfig = {
      title: i18next.t('action.import'),
      continueTitle: i18next.t('action.confirm'),
      onClose: () => this.setState({ importConfirmDialogOpen: undefined }),
      onContinue: () => this.state.importConfirmDialogOpen && this.confirmImportData(),
    };

    this.importInProgressDialogConfig = {
      title: i18next.t('action.import'),
      description: (
        <div style={{width: '500px'}}>
          {i18next.t('description.import.inProgress')}
        </div>
      ),
      onClose: () => {return;},
      onContinue: () => {return;},
      isShowCancelButton: false,
      isShowContinueButton: false
    };

    this.importFailedDialogConfig = {
      title: i18next.t('action.import'),
      description: (
        <div style={{width: '500px'}}>
          <p>{i18next.t('description.import.failReasonFormat')}</p>
          <p>{i18next.t('description.import.failReasonFormatDescription')}</p>
        </div>
      ),
      cancelTitle: i18next.t('action.close'),
      onClose: () => this.setState({ importFailedDialogOpen: undefined }),
      onContinue: () => {return;},
      isShowCancelButton: true,
      isShowContinueButton: false
    };

    this.importCompletedDialogConfig = {
      title: i18next.t('action.import'),
      description: (
        <div style={{width: '500px'}}>
          {i18next.t('description.import.completed')}
        </div>
      ),
      onClose: async () => {
        this.setState({ importCompletedDialogOpen: undefined })
        const params = { ...this.state.params, limit: 50 };
        try {
          const response = (await this.list({ params })) as any;
          console.log(response)
          if (!this.isUnmounted)
            this.setState({
              loading: false,
              items: response.data.data,
              nextToken: response.data.nextToken,
              count: (response.data as any).count,
            } as any);
        } catch (e) {
          if (!this.isUnmounted) this.setState({ loading: false } as any);
          throw e;
        }
      },
      cancelTitle: i18next.t('action.close'),
      onContinue: () => {return;},
      isShowCancelButton: true,
      isShowContinueButton: false
    };

    this.dialogConfig = {
      title: i18next.t('action.contractTerminate'),
      description: i18next.t('description.terminateContract'),
      continueTitle: i18next.t('action.contractTerminate'),
      onClose: () => this.setState({ dialogOpen: undefined }),
      onContinue: () => this.state.dialogOpen && this.updateContractStatus(this.state.dialogOpen, "Standby to Terminate"),
    };
    
    this.fabConfig = [
      {
        title: i18next.t('action.createDevice'),
        action: () => this.props.openModal(this.reloadList),
        icon: itemIcons.device,
        tryAccessRight: ['device', 'create'],
      },
    ];

    // Functions
    this.getUrlPathForItem = SubdomainRoutePath.device;
  }

  // parseCSV = (file: File) => {
  //   const reader = new FileReader();

  //   reader.onload = () => {
  //     const text = reader.result as string;
  //     Papa.parse(text, {
  //       complete: (result: any) => {
  //         const dataJson = JSON.stringify(result.data.slice(1))
  //         this.setState({importDataJson: dataJson});
  //       },
  //       skipEmptyLines: true, 
  //       header: false,
  //     });
  //   };

  //   reader.readAsText(file);
  // };

  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    this.setState({ exportDataType: value });
  };

  uploadFile = (files: any[]) => {
    this.setState({ importFiles: files })
    // if (files && files.length > 0) {
    //   this.parseCSV(files[0]);
    // }
  }

  componentDidMount = async () => {
    this.service = await DeviceService.create();
    this.list = this.service!.device.list;
    const predefinedFilters = this.filterService!.get();
    const index = this.filterService!.getIndex();
    this.setState({ initLoaded: true, predefinedFilters, activeTabIndex: index });
  };

  toggleBetweebActiveDeactive = async (device: string): Promise<void> => {
    try {
      this.setState({ loading: true });
      let newItem = this.state.items.find((item) => item.deviceId === device);
      if (newItem && !this.isUnmounted) {
        newItem = {
          ...newItem,
          state: DeviceState.activated === newItem?.state ? DeviceState.deactivated : DeviceState.activated,
        };
        await this.service.device.put(device, { state: newItem.state });
        const items = this.state.items.map((d: Device) => {
          if (d.deviceId === device) {
            return newItem;
          }
          return d;
        });
        this.setState({ items });
        SnackbarUtils.success(i18next.t('success.update.device'));
        if (!this.isUnmounted) this.setState({ loading: false });
      } else {
        SnackbarUtils.error(i18next.t('error.tryAgain'));
      }
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      if (!this.isUnmounted) this.setState({ loading: false });
    }
  };

  // removeDevice = async (device: string): Promise<void> => {
  //   try {
  //     await this.service.device.del(device);
  //     SnackbarUtils.success(i18next.t('success.delete.device'));
  //     if (!this.isUnmounted) {
  //       const items = this.state.items.filter((d: Device) => d.deviceId !== device);
  //       this.setState({ items });
  //     }
  //   } catch (e) {
  //     const error: any = e;
  //     SnackbarUtils.error(
  //       (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
  //     );
  //     if (!this.isUnmounted) this.setState({ loading: false });
  //   }
  // };

  exportDeviceList = async (): Promise<void> => {
    const filterConditions = { ...filterUtils.createFilterAndMetaDataFromUrl(window.location.search) }
    const params = filterUtils.createParamsFromFilter(filterConditions.filters);
    // const displayNamesObject = Object.values(Object.entries(this.tableConfig.tableProperties).reduce(
    //   (acc: any, [key]: [string, ListTableProperty]) => {
    //     if (this.state.properties.includes(key)) acc[key] = key;
    //     return acc;
    //   },
    //   {},
    // ))
    // const displayNames = ["deviceId","name","imei","imsi","productId","activated","deviceId","deviceId"];
    // const displayNamesObjectValues = Object.values(displayNamesObject) as string[];
    const exportData = this.state.exportDataType === "1" ? await this.service.device.getAllDevices({ query: "*" }) : await this.service.device.getAllDevices({ params })
    // const filteredData = exportData.data.data.map((item: { [x: string]: any }) => {
    //   return displayNamesObjectValues.reduce((acc, key) => {
    //       if (Object.prototype.hasOwnProperty.call(item, key)) {
    //           acc[key] = item[key];
    //       }
    //       return acc;
    //   }, {} as Record<string, any>);
    // });
    const csv = Papa.unparse(exportData.data.data);

    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "export_csv_for_update_contract.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  importDeviceList = async (): Promise<void> => {
    try {
      if (this.state.importFiles !== undefined) {
        const prepareData = await this.service.device.prepare();
        await this.service.authenticationServiceProvider
        .uploadFile({
          Bucket: prepareData.data.bucket,
          Key: prepareData.data.key,
          Body: this.state.importFiles[0],
          Metadata: { token: prepareData.data.token },
        }).promise();
        const csvKey = prepareData.data.key.split("/")[2];
        const checkResult = await this.service.device.checkContractCsv({csvKey});
        this.setState(
          {
            importDialogOpen: false,
            importConfirmDialogOpen: true,
            checkResult: checkResult.data,
            confirmContent: (
              <div style={{width: '500px'}}>
                {checkResult.data.stateChanges?.Active && (
                  <p>{checkResult.data.stateChanges.Active} devices change to Active</p>
                )}
                {checkResult.data.stateChanges && checkResult.data.stateChanges["Pause"] && (
                  <p>{checkResult.data.stateChanges["Pause"]} devices change to Standby to pause</p>
                )}
                {checkResult.data.stateChanges && checkResult.data.stateChanges["Terminate"] && (
                  <p>{checkResult.data.stateChanges["Terminate"]} devices change to Standby to terminate</p>
                )}
              </div>
            ),
            csvKey,
          }
        );
      }
    } catch (e) {
      this.setState({ importDialogOpen: false });
      this.setState({ importFailedDialogOpen: true });
    }
  }

  confirmImportData = async (): Promise<void> => {
    this.setState({ importConfirmDialogOpen: false });
    this.setState({ importInProgressDialogOpen: true });
    console.log(123)
    console.log(this.state.csvKey)
    await this.service.device.updateContractByCsv(this.state.csvKey);
    console.log(456)
    this.setState({ importInProgressDialogOpen: false });
    this.setState({ importCompletedDialogOpen: true });
  }

  updateContractStatus = async (device: string, status: string): Promise<void> => {
    try {
      this.setState({ loading: true });
      let newItem = this.state.items.find((item) => item.deviceId === device);
      if (newItem && !this.isUnmounted) {
        newItem = {
          ...newItem,
          contract: status,
        };
        await this.service.device.putContract(
          device,
          {status},
        );
        const items = this.state.items.map((d: Device) => {
          if (d.deviceId === device) {
            return newItem;
          }
          return d;
        });
        this.setState({ items });
        SnackbarUtils.success(i18next.t('success.update.contract'));
        if (!this.isUnmounted) this.setState({ loading: false });
      } else {
        SnackbarUtils.error(i18next.t('error.tryAgain'));
      }
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
      if (!this.isUnmounted) this.setState({ loading: false });
    }
  };
}

const mapStateToProps = ({ deviceStore }: Store) => ({
  shadowAliases: deviceStore.shadowAliases,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  openModal: (callback: () => any) => dispatch(storeOpenModal(ModalVariants.AuthorizeDeviceMC, { callback })),
});

export default withStyles(ListBaseStyles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceView)));
