import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@mui/material';
import { PageLoader, TabPanel, SchemaForm } from '../../../../components';
import { DeviceService } from '../../../../services';
import { TabViewBase, TabViewBaseProps, TabViewBaseState } from '../../../baseClasses/TabViewBase';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import i18next from 'i18next';
import { schemas } from '../../../../assets/contractSchemas';
import schemaUtils from '../../../../common/schemaUtils';
import { SnackbarUtils } from '../../../../components/StyledSnackbarProvider';

type Props = RouteComponentProps<{ deviceId: string }> & {
  customAction?: React.ReactNode;
};

type State = {
  editedItem: { [key: string]: any };
  saveDisabled: boolean;
  schemaFormInputData: { [key: string]: any[] };
  defaultContractValue: { [key: string]: any };
  statusOptions: { value: string; label: string, disabled: boolean }[];
  statusMessage: string;
  standByText: string;
};
class ContradctTab extends TabViewBase<Props, State> {
  state: TabViewBaseState<State> = {
    loading: false,
    editing: false,
    editedItem: {},
    saveDisabled: true,
    schemaFormInputData: {},
    defaultContractValue: {},
    statusOptions: [],
    statusMessage: "",
    standByText: ""
  };
  DeviceService!: DeviceService;

  constructor(props: TabViewBaseProps<Props>) {
    super(props);
    DeviceService.create().then((service) => {(this.service = service);});
  }

  reload = async (): Promise<void> => {
    this.setState({ loading: true, editing: false });
    try {
      const { data: contract } = await this.service.device.getContract({
        deviceId: this.props.match.params.deviceId,
      });
      const { data: history } = await this.service.device.getContractHistory({
        deviceId: this.props.match.params.deviceId,
      });
      await this.fetchStatusOptions(contract, history);

      this.setState({
        defaultContractValue: contract,
      });
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false, editing: false });
  };

  putContract = async (): Promise<void> => {
    this.setState({ loading: true });
    try {
      await this.service.device.putContract(
        this.props.match.params.deviceId,
        this.state.editedItem,
      );
      const { data: newContract } = await this.service.device.getContract({
        deviceId: this.props.match.params.deviceId,
      });
      const { data: history } = await this.service.device.getContractHistory({
        deviceId: this.props.match.params.deviceId,
      });
      await this.fetchStatusOptions(newContract, history);
      this.setState({
        defaultContractValue: newContract,
      });
      SnackbarUtils.success(i18next.t('success.update.deviceContract'));
    } catch (e) {
      const error: any = e;
      SnackbarUtils.error(
        (error.response && error.response.data && error.response.data.message) || i18next.t('error.tryAgain'),
      );
    }
    this.setState({ loading: false, editing: false });
  };

  getNextMonthFirstDay = () => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    currentDate.setDate(1);
  
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0"); 
  
    return `${year}/${month}/${day}`;
  };

  fetchStatusOptions = async (contract: any, history: any): Promise<void> => {
    switch (contract.status) {
      case "Not Start":
        this.setState({
          standByText: "",
          statusMessage: "",
          statusOptions: [
            { value: 'Not Start', label: 'Not Start', disabled: false },
            { value: 'Active', label: 'Active', disabled: false },
            { value: 'Standby to Pause', label: 'Standby to Pause', disabled: false },
            { value: 'Pause', label: 'Pause', disabled: true },
            { value: 'Standby to Terminate', label: 'Standby to Terminate', disabled: false },
            { value: 'Terminated', label: 'Terminated', disabled: true }
          ],
        });
        break;
      case "Active":
        this.setState({
          standByText: "",
          statusMessage: "",
          statusOptions: [
            { value: 'Not Start', label: 'Not Start', disabled: true },
            { value: 'Active', label: 'Active', disabled: false },
            { value: 'Standby to Pause', label: 'Standby to Pause', disabled: false },
            { value: 'Pause', label: 'Pause', disabled: true },
            { value: 'Standby to Terminate', label: 'Standby to Terminate', disabled: false },
            { value: 'Terminated', label: 'Terminated', disabled: true }
          ],
        });
        break;
      case "Standby to Pause":
        if (this.isEventInCurrentMonth(history, "Change to Standby to Pause")) {
          const secondLatestContractStatus = this.getSecondLatestStatus(history);
          this.setState({
            standByText: secondLatestContractStatus,
            statusMessage: `(Change to pause from ${this.getNextMonthFirstDay()})`,
            statusOptions: [
              { value: 'Not Start', label: 'Not Start', disabled: true },
              { value: 'Active', label: 'Active', disabled: false },
              { value: 'Standby to Pause', label: 'Standby to Pause', disabled: false },
              { value: 'Pause', label: 'Pause', disabled: true },
              { value: 'Standby to Terminate', label: 'Standby to Terminate', disabled: false },
              { value: 'Terminated', label: 'Terminated', disabled: true }
            ],
          });
          return;
        }
        this.setState({
          standByText: "",
          statusMessage: "",
          statusOptions: [
            { value: 'Not Start', label: 'Not Start', disabled: true },
            { value: 'Active', label: 'Active', disabled: false },
            { value: 'Standby to Pause', label: 'Standby to Pause', disabled: false },
            { value: 'Pause', label: 'Pause', disabled: true },
            { value: 'Standby to Terminate', label: 'Standby to Terminate', disabled: false },
            { value: 'Terminated', label: 'Terminated', disabled: true }
          ],
        });
        break;
      case "Pause":
        this.setState({
          standByText: "",
          statusMessage: "",
          statusOptions: [
            { value: 'Not Start', label: 'Not Start', disabled: true },
            { value: 'Active', label: 'Active', disabled: false },
            { value: 'Standby to Pause', label: 'Standby to Pause', disabled: true },
            { value: 'Pause', label: 'Pause', disabled: true },
            { value: 'Standby to Terminate', label: 'Standby to Terminate', disabled: false },
            { value: 'Terminated', label: 'Terminated', disabled: true }
          ],
        });
        break;
      case "Standby to Terminate":
        if (this.isEventInCurrentMonth(history, "Change to Standby to Terminate")) {
          const secondLatestContractStatus = this.getSecondLatestStatus(history);
          this.setState({
            standByText: secondLatestContractStatus,
            statusMessage: `(Change to terminate from ${this.getNextMonthFirstDay()})`,
            statusOptions: [
              { value: 'Not Start', label: 'Not Start', disabled: true },
              { value: 'Active', label: 'Active', disabled: false },
              { value: 'Standby to Pause', label: 'Standby to Pause', disabled: false },
              { value: 'Pause', label: 'Pause', disabled: true },
              { value: 'Standby to Terminate', label: 'Standby to Terminate', disabled: false },
              { value: 'Terminated', label: 'Terminated', disabled: true }
            ],
          });
          return;
        }
        this.setState({
          standByText: "",
          statusMessage: "",
          statusOptions: [
            { value: 'Not Start', label: 'Not Start', disabled: true },
            { value: 'Active', label: 'Active', disabled: false },
            { value: 'Standby to Pause', label: 'Standby to Pause', disabled: false },
            { value: 'Pause', label: 'Pause', disabled: true },
            { value: 'Standby to Terminate', label: 'Standby to Terminate', disabled: false },
            { value: 'Terminated', label: 'Terminated', disabled: true }
          ],
        });
        break;
      case "Terminated":
        this.setState({
          standByText: "",
          statusMessage: "",
          statusOptions: [
            { value: 'Not Start', label: 'Not Start', disabled: true },
            { value: 'Active', label: 'Active', disabled: true },
            { value: 'Standby to Pause', label: 'Standby to Pause', disabled: true },
            { value: 'Pause', label: 'Pause', disabled: true },
            { value: 'Standby to Terminate', label: 'Standby to Terminate', disabled: true },
            { value: 'Terminated', label: 'Terminated', disabled: false }
          ],
        });
        break;
      default:
        this.setState({
          standByText: "",
          statusMessage: "",
          statusOptions: [
            { value: 'Not Start', label: 'Not Start', disabled: false },
            { value: 'Active', label: 'Active', disabled: false },
            { value: 'Standby to Pause', label: 'Standby to Pause', disabled: false },
            { value: 'Pause', label: 'Pause', disabled: true },
            { value: 'Standby to Terminate', label: 'Standby to Terminate', disabled: false },
            { value: 'Terminated', label: 'Terminated', disabled: true }
          ],
        });
        break;
    }
  };

  isEventInCurrentMonth = (events: any, eventType: string) => {
    // filter eventType data
    const filteredEvents = events.filter((event: { events: string; }) => event.events === eventType);
    // find last new data
    const latestEvent = filteredEvents.reduce((latest: { ts: string; }, current: { ts: string; }) => {
      return (parseInt(current.ts) > parseInt(latest.ts)) ? current : latest;
    });
    // get data's date
    const eventDate = new Date(latestEvent.eventDate);
    // get current date
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth(); // 0-11
    // Determine whether the hisory data is in the current month
    return eventDate.getFullYear() === currentYear && eventDate.getMonth() === currentMonth;
  }

  getSecondLatestStatus = (events: any[]) => {
    // Sort the events by the 'ts' (timestamp) field in descending order to get the most recent events
    const sortedEvents = events.sort((a, b) => parseInt(b.ts) - parseInt(a.ts));
  
    // Initialize a variable to track the index of the event to be checked
    let index = 1; // Start with the second newest event (index 1)
  
    // Loop to find the second most recent status that is not 'Standby to Pause' or 'Standby to Terminate'
    while (index < sortedEvents.length) {
      const event = sortedEvents[index];
  
      // If the event's status is not 'Standby to Pause' or 'Standby to Terminate', return the status
      if (event.status !== "Standby to Pause" && event.status !== "Standby to Terminate") {
        return event.status;
      }
  
      // If the status matches, move to the next event
      index++;
    }
  
    // If no suitable status is found, return null
    return null;
  };

  handleUpdate = (key: string, value: any, isValid: boolean): void => {
    const editedItem = schemaUtils.setValue(
      this.state.editedItem || {},
      key === undefined ? [] : key.split('.'),
      value,
    );
    this.setState({ editedItem: editedItem || {}, saveDisabled: !(!!Object.keys(editedItem).length && isValid) });
  };

  renderContent = (): React.ReactNode => {
    if (!schemas.contract) return null;
    const updatedSchemas = { ...schemas };
    if (updatedSchemas.contract.properties.status) {
      updatedSchemas.contract.properties.status.options = this.state.statusOptions;
      updatedSchemas.contract.properties.status.message = this.state.statusMessage;
      updatedSchemas.contract.properties.status.standByText = this.state.standByText;
    }

    return (
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SchemaForm
            state="UPDATE"
            key={`schema-editing-${JSON.stringify(this.state.defaultContractValue)}`}  
            data={{
              ...(schemas.contract as any),
            }}
            disabled={!this.state.editing}
            defaultValue={this.getDefaultValue()}
            onChange={this.handleUpdate}
          />
        </Grid>
      </Grid>
    )
  };

  getDefaultValue = () => {
    return this.state.defaultContractValue || {};
  };

  render = (): React.ReactNode => (
    <TabPanel
      tab={this.props.tab}
      activeTab={this.props.activeTab}
      headerProps={{
        actionProps: {
          actionTitle: 'action.refresh',
          onAction: this.reload,
          disabled: this.props.parentLoading || this.state.loading,
        },
        customAction: this.props.customAction,
        ...(this.props.accessControl("device", 'update')
          ? {
              editProps: {
                loading: this.state.loading,
                editing: this.state.editing,
                saveDisabled: this.state.saveDisabled,
                editDisabled: false,
                onEdit: () => {
                  this.setState({ editing: true });
                },
                onSave: this.putContract,
                onCancel: () => this.setState({ editing: false }),
              },
            }
          : {}),
      }}
    >
        {(this.props.parentLoading && (!this.state.defaultContractValue || this.state.editing)) ||
        (!this.state.editing && this.state.loading) ? (
          <PageLoader />
        ) : null}
        {(!this.props.parentLoading && (this.state.defaultContractValue || this.state.editing)) ||
        (!this.state.editing && !this.state.loading)
          ? this.renderContent()
          : null}
    </TabPanel>
  );
}

const mapStateToProps = (/*{ deviceStore }: Store*/) => ({
  shadowAliases: undefined, //deviceStore.shadowAliases,
});

export default withRouter(connect(mapStateToProps)(ContradctTab));
